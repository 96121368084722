.aaaem-back-to-top-button {
  border-width: var(--emu-common-border-width-thin);
  border-style: solid;
  border-color: var(--emu-common-colors-white);
  background: rgba(71, 46, 127, 0.4); // As per figma design.
  padding: var(--emu-common-spacing-small);

  @include mq('xx-large') {
    right: calc(50% - 704px); // To match figma design.
  }
}
