#fap {
  .bg--mix-purple {
    //to match with figma
    background: linear-gradient(
      110deg,
      #06004d 0%,
      var(--emu-semantic-colors-primary-purple-200) 6%,
      var(--emu-semantic-colors-primary-purple-300) 14%,
      var(--emu-semantic-colors-primary-purple-400) 24%,
      var(--emu-semantic-colors-primary-purple-500) 35%,
      var(--emu-semantic-colors-primary-purple-700) 48%,
      var(--emu-semantic-colors-primary-purple-800) 61%,
      var(--emu-semantic-colors-primary-purple-900) 72%,
      var(--emu-semantic-colors-primary-pink-100) 82%,
      var(--emu-semantic-colors-primary-pink-200) 90%,
      var(--emu-semantic-colors-primary-pink-400) 96%,
      var(--emu-semantic-colors-primary-pink-500) 100%
    );
  }

  .fap-hero-section {
    padding-top: 48px;
    padding-right: var(--emu-common-spacing-small);
    padding-bottom: 40px;
    padding-left: var(--emu-common-spacing-small);

    @include mq('large') {
      padding-left: var(--emu-common-spacing-large);
      padding-right: var(--emu-common-spacing-large);
      padding-bottom: 44px;
      padding-top: 150px;
    }

    &__title {
      margin-bottom: 20px;

      @include mq('large') {
        margin-bottom: 22px;
      }

      h1 {
        color: var(--emu-common-colors-white);

        @include mq('large') {
          text-align: center;
          font-size: var(--emu-semantic-font-sizes-wide-xxxl);
          line-height: var(--emu-semantic-line-heights-wide-xxxl);
        }
      }
    }

    &__subtitle {
      margin-bottom: 40px;

      @include mq('large') {
        margin-bottom: 44px;
      }

      p {
        color: var(--emu-common-colors-white);
        margin-bottom: 20px;

        @include mq('large') {
          text-align: center;
          font-size: var(--emu-semantic-font-sizes-wide-medium);
          line-height: var(--emu-semantic-line-heights-wide-large);
        }
      }
    }
  }

  .form-container {
    @include mq('large') {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 44px;
      margin-bottom: 44px;
      max-width: 1168px; // to match the design

      > .container {
        width: calc(50% - 50px);
      }
    }
  }
}
