[data-component='text'],
[data-component='title'] {
  color: var(--emu-common-colors-black);
  font: var(--emu-semantic-typography-narrow-body-regular);
  p,
  li {
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    font-family: var(--emu-semantic-font-families-body);
    margin: var(--emu-common-spacing-none);
  }

  sup {
    font-size: 65%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sup {
    top: -1px;
  }

  b {
    font-weight: var(--emu-common-font-weight-bold);
  }
}
