.aaaem-card {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;

  @include mq('large') {
    max-width: none;
  }

  &__title-wrapper {
    display: none;
  }

  &__content-wrapper {
    padding-top: 15px;
  }

  &__body {
    padding-top: 15px;
  }
}
