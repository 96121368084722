.emu-navigation {
  &__content-wrapper {
    position: relative;
    nav {
      ul {
        flex-direction: column;
        @include mq('large') {
          flex-direction: row;
        }
      }
    }
  }
  ul {
    li {
      border-bottom: var(--emu-common-border-width-thin) solid
        var(--emu-common-colors-black);
      @include mq('large') {
        border-bottom-width: var(--emu-common-border-width-none);
      }

      a {
        font-weight: var(--emu-common-font-weight-regular);
        font-size: 20px;
        line-height: 24px;
        display: flex;
        justify-content: space-between;

        img {
          width: var(--emu-common-sizing-small);
          height: auto;
        }

        @include mq('large') {
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
          padding-top: var(--emu-common-spacing-none);
          padding-bottom: var(--emu-common-spacing-none);
          font-size: var(--emu-common-font-sizes-wide-medium);
          line-height: 20px;  
          margin-left: 15px;
          white-space: pre;
        }

        @include mq('large') {
          font-size: var(--emu-semantic-font-sizes-wide-medium);
          margin-left: 20px;  //to match figma
          line-height: var(--emu-semantic-line-heights-wide-large);
        }

        @include mq('x-large') {
          margin-left: 40px;  //to match figma
        }
        img {
          width: var(--emu-common-sizing-small); //as per figma
          height: var(--emu-common-sizing-small); //as per figma
          margin-right: var(--emu-common-spacing-xxs);
          margin-top: 3px;
        }
      }
    }

    > li {
      span.nav-item {
        width: 100%;
        padding: var(--emu-common-spacing-none);

        @include mq('large') {
          display: none;
        }

        a {
          color: inherit;
        }
      }
    }
  }
}
