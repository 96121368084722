// form related styles
.form-container {
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: var(--emu-common-spacing-small);
  margin-right: var(--emu-common-spacing-small);
  padding-left: var(--emu-common-spacing-medium);
  padding-right: var(--emu-common-spacing-medium);
  padding-top: var(--emu-common-spacing-small);
  padding-bottom: 20px;
  border-radius: var(--emu-semantic-border-radius-small);
  border: var(--emu-common-border-width-thin) solid
    var(--emu-semantic-colors-primary-purple-1200);

  // to remove the spacings added by hidden input elements
  .hidden {
    display: none;
  }

  // to adjust the position of loader
  .cmp-ajax-form {
    position: relative;

    .emu-loader {
      position: absolute;
      height: 100%;
      display: flex;
      z-index: var(--emu-common-other-z-index-layer);
      background: rgba(255, 255, 255, 0.4);
    }

    .emu-loader__indicator {
      margin-bottom: 80px;
    }
  }

  @include mq('large') {
    margin-top: 84px;
    margin-bottom: 84px;
    padding-top: var(--emu-common-spacing-medium);
    padding-right: var(--emu-common-spacing-large);
    padding-left: var(--emu-common-spacing-large);
    padding-bottom: 22px;
  }

  @include mq('x-large') {
    margin-left: auto;
    margin-right: auto;
  }

  &__btn-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include mq('large') {
      gap: 22px;
    }
  }

  &__top {
    margin-bottom: 40px; // to match figma design
    scroll-margin-top: 80px; // to keep the space between header & form container when it scrolls to top

    @include mq('large') {
      margin-bottom: 44px;
    }

    &-title {
      h2 {
        color: var(--emu-semantic-colors-primary-pink-600);
        font-weight: var(--emu-semantic-font-weight-bold);

        @include mq('large') {
          font-size: 26px;
          line-height: 33px;
        }
      }
    }

    &-subtitle {
      h3 {
        padding-bottom: 20px;

        @include mq('large') {
          padding-bottom: 22px;
          font-size: var(--emu-semantic-font-sizes-wide-xl);
          line-height: var(--emu-semantic-line-heights-wide-xl);
        }
      }
    }

    &-text {
      &.aaaem-text {
        @include mq('large') {
          font-size: var(--emu-semantic-font-sizes-wide-medium);
          line-height: var(--emu-semantic-line-heights-wide-large);
        }
      }
    }
  }

  &__bottom {
    &-text[data-component='text'] {
      > p {
        font-size: 12.5px; //according to figma

        @include mq('large') {
          line-height: var(--emu-semantic-line-heights-wide-large);
          font-size: 11.48px;
        }

        a,
        a:hover,
        a:focus {
          color: var(--emu-common-colors-black);
        }
      }
    }
  }
}

// message icon, text and patient name
.patient-testimonial {
  display: flex;
  flex-direction: column;
  row-gap: 15px;

  &__pl-8[data-component='container'] {
    //to match padding left from 1024 to 1200
    padding-left: var(--emu-common-spacing-small);
  }
  img {
    max-width: var(--emu-common-sizing-medium);
  }

  .cmp-text p {
    line-height: var(--emu-semantic-line-heights-narrow-large);
    font-weight: var(--emu-common-font-weight-bold);
  }

  &__text[data-component='text'] p {
    font-size: 15px;
    color: var(--emu-semantic-colors-primary-pink-500);
  }

  &__name[data-component='text'] {
    p {
      font-size: 12.5px;
      color: var(--emu-semantic-colors-secondary-dark-gray-200);
      font-weight: var(--emu-common-font-weight-bold);
    }
    h5 {
      font-weight: var(--emu-common-font-weight-bold);
      line-height: var(--emu-semantic-line-heights-narrow-large);
      font-size: 12.5px;
      @include mq('large') {
        font-weight: var(--emu-common-font-weight-regular);
      }
    }
  }

  &--white {
    .cmp-text p {
      color: var(--emu-common-colors-white);
      line-height: va(--emu-semantic-line-heights-narrow-large);
      font-size: 15px;
      font-weight: var(--emu-common-font-weight-bold);
      @include mq('large') {
        line-height: var(--emu-semantic-line-heights-wide-large);
      }
    }

    .patient-testimonial__name[data-component='text'] p {
      font-weight: var(--emu-common-font-weight-regular);
      font-size: 12.5px;
      @include mq('large') {
        font-size: 11.5px;
      }
    }
  }
}
