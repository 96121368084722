@font-face {
  font-family: Gilroy;
  src: url("resources/fonts/gilroy-black.woff2") format("woff"), url("resources/fonts/gilroy-black.woff") format("woff");
  font-display: swap;
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: Gilroy;
  src: url("resources/fonts/gilroy-blackitalic.woff2") format("woff"), url("resources/fonts/gilroy-blackitalic.woff") format("woff");
  font-display: swap;
  font-style: italic;
  font-weight: 900;
}

@font-face {
  font-family: Gilroy;
  src: url("resources/fonts/gilroy-bold.woff2") format("woff"), url("resources/fonts/gilroy-bold.woff") format("woff");
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: Gilroy;
  src: url("resources/fonts/gilroy-bolditalic.woff2") format("woff"), url("resources/fonts/gilroy-bolditalic.woff") format("woff");
  font-display: swap;
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: Gilroy;
  src: url("resources/fonts/gilroy-extrabold.woff2") format("woff"), url("resources/fonts/gilroy-extrabold.woff") format("woff");
  font-display: swap;
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: Gilroy;
  src: url("resources/fonts/gilroy-extrabolditalic.woff2") format("woff"), url("resources/fonts/gilroy-extrabolditalic.woff") format("woff");
  font-display: swap;
  font-style: italic;
  font-weight: 800;
}

@font-face {
  font-family: Gilroy;
  src: url("resources/fonts/gilroy-regularitalic.woff2") format("woff"), url("resources/fonts/gilroy-regularitalic.woff") format("woff");
  font-display: swap;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: Gilroy;
  src: url("resources/fonts/gilroy-light.woff2") format("woff"), url("resources/fonts/gilroy-light.woff") format("woff");
  font-display: swap;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: Gilroy;
  src: url("resources/fonts/gilroy-lightitalic.woff2") format("woff"), url("resources/fonts/gilroy-lightitalic.woff") format("woff");
  font-display: swap;
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: Gilroy;
  src: url("resources/fonts/gilroy-medium.woff2") format("woff"), url("resources/fonts/gilroy-medium.woff") format("woff");
  font-display: swap;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: Gilroy;
  src: url("resources/fonts/gilroy-mediumitalic.woff2") format("woff"), url("resources/fonts/gilroy-mediumitalic.woff") format("woff");
  font-display: swap;
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: Gilroy;
  src: url("resources/fonts/gilroy-regular.woff2") format("woff"), url("resources/fonts/gilroy-regular.woff") format("woff");
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}

body {
  font-family: var(--emu-semantic-font-families-heading);
  font-weight: var(--emu-semantic-font-weight-regular);
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--emu-semantic-font-families-heading);
  font-weight: var(--emu-semantic-font-weight-bold);
  color: inherit;
  margin: var(--emu-common-spacing-none);
}

h1 {
  font: var(--emu-component-ingredients-title-h1-typography-narrow);
}

h2 {
  font: var(--emu-component-ingredients-title-h2-typography-narrow);
}

h3 {
  font: var(--emu-component-ingredients-title-h3-typography-narrow);
}

h4 {
  font: var(--emu-component-ingredients-title-h4-typography-narrow);
}

h5 {
  font: var(--emu-component-ingredients-title-h5-typography-narrow);
}

:root {
  --emu-common-spacing-xs: 4px;
  --emu-common-spacing-xxs: 2px;
  --emu-common-spacing-small: 8px;
  --emu-common-spacing-medium: 16px;
  --emu-common-spacing-large: 32px;
  --emu-common-spacing-xl: 64px;
  --emu-common-spacing-none: 0px;
  --emu-common-spacing-unset: unset;
  --emu-common-colors-white: #fff;
  --emu-common-colors-black: #000;
  --emu-common-colors-red-100: #ff7570;
  --emu-common-colors-red-200: #ff615c;
  --emu-common-colors-red-300: #ff4e47;
  --emu-common-colors-red-400: #ff3a33;
  --emu-common-colors-red-500: #ff1d15;
  --emu-common-colors-red-600: #ff120a;
  --emu-common-colors-red-700: #f50800;
  --emu-common-colors-red-800: #e00700;
  --emu-common-colors-red-900: #cc0700;
  --emu-common-colors-grey-100: #f4f5f5;
  --emu-common-colors-grey-200: #d4d7d8;
  --emu-common-colors-grey-300: #b4b8bb;
  --emu-common-colors-grey-400: #949a9e;
  --emu-common-colors-grey-500: #7e868b;
  --emu-common-colors-grey-600: #61676b;
  --emu-common-colors-grey-700: #44484b;
  --emu-common-colors-grey-800: #303436;
  --emu-common-colors-grey-900: #1d1f20;
  --emu-common-colors-transparent: #0000;
  --emu-common-font-families-sans: Arial;
  --emu-common-font-families-serif: Times New Roman;
  --emu-common-font-families-mono: Courier;
  --emu-common-font-weight-bold: 700;
  --emu-common-font-weight-light: 300;
  --emu-common-font-weight-black: 900;
  --emu-common-font-weight-regular: 400;
  --emu-common-font-sizes-narrow-medium: 12px;
  --emu-common-font-sizes-narrow-large: 16.97px;
  --emu-common-font-sizes-narrow-xl: 23.99px;
  --emu-common-font-sizes-narrow-xxl: 33.93px;
  --emu-common-font-sizes-narrow-xxxl: 47.97px;
  --emu-common-font-sizes-wide-medium: 16px;
  --emu-common-font-sizes-wide-large: 22.62px;
  --emu-common-font-sizes-wide-xl: 31.99px;
  --emu-common-font-sizes-wide-xxl: 45.23px;
  --emu-common-font-sizes-wide-xxxl: 63.96px;
  --emu-common-line-heights-narrow-large: 18px;
  --emu-common-line-heights-narrow-medium: 18px;
  --emu-common-line-heights-narrow-xl: 36px;
  --emu-common-line-heights-narrow-xxl: 36px;
  --emu-common-line-heights-narrow-xxxl: 54px;
  --emu-common-line-heights-wide-large: 24px;
  --emu-common-line-heights-wide-medium: 24px;
  --emu-common-line-heights-wide-xl: 48px;
  --emu-common-line-heights-wide-xxl: 48px;
  --emu-common-line-heights-wide-xxxl: 72px;
  --emu-common-border-radius-xxs: 2px;
  --emu-common-border-radius-xs: 4px;
  --emu-common-border-radius-small: 8px;
  --emu-common-border-radius-medium: 16px;
  --emu-common-border-radius-large: 32px;
  --emu-common-border-radius-none: 0px;
  --emu-common-border-width-thin: 1px;
  --emu-common-border-width-medium: 2px;
  --emu-common-border-width-thick: 4px;
  --emu-common-border-width-none: 0px;
  --emu-common-outline-width-thin: thin;
  --emu-common-outline-width-medium: medium;
  --emu-common-outline-width-thick: thick;
  --emu-common-outline-width-unset: unset;
  --emu-common-sizing-none: 0px;
  --emu-common-sizing-xxs: 1px;
  --emu-common-sizing-xs: 8px;
  --emu-common-sizing-small: 16px;
  --emu-common-sizing-medium: 32px;
  --emu-common-sizing-large: 64px;
  --emu-common-sizing-xl: 128px;
  --emu-common-sizing-xxl: 256px;
  --emu-common-sizing-container: 1520px;
  --emu-common-other-time-transition-short: 200ms;
  --emu-common-other-time-transition-base: 400ms;
  --emu-common-other-time-transition-long: 600ms;
  --emu-common-other-time-transition-xl: 1000ms;
  --emu-common-other-time-duration-instant: 400ms;
  --emu-common-other-time-duration-short: 2000ms;
  --emu-common-other-time-duration-base: 4000ms;
  --emu-common-other-time-duration-long: 6000ms;
  --emu-common-other-time-delay-none: 0ms;
  --emu-common-other-time-delay-short: 50ms;
  --emu-common-other-time-delay-base: 100ms;
  --emu-common-other-time-delay-long: 200ms;
  --emu-common-other-z-index-cookie-banner: 700;
  --emu-common-other-z-index-modal: 600;
  --emu-common-other-z-index-header: 500;
  --emu-common-other-z-index-isi: 400;
  --emu-common-other-z-index-overlay: 300;
  --emu-common-other-z-index-layer: 100;
  --emu-common-other-z-index-base: 0;
  --emu-common-other-z-index-behind: -1;
  --emu-semantic-colors-none: var(--emu-common-colors-transparent);
  --emu-semantic-colors-surface-light: var(--emu-common-colors-white);
  --emu-semantic-colors-surface-dark: var(--emu-common-colors-black);
  --emu-semantic-colors-surface-modal-backdrop: #0006;
  --emu-semantic-colors-actions-primary-light: var(--emu-common-colors-red-500);
  --emu-semantic-colors-actions-primary-dark: var(--emu-common-colors-red-900);
  --emu-semantic-colors-actions-on-primary-light: var(--emu-common-colors-black);
  --emu-semantic-colors-actions-on-primary-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-text-light: var(--emu-common-colors-black);
  --emu-semantic-colors-text-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-foreground-light: var(--emu-common-colors-black);
  --emu-semantic-colors-foreground-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-primary-purple-100: #06004a;
  --emu-semantic-colors-primary-purple-200: #170656;
  --emu-semantic-colors-primary-purple-300: #270a61;
  --emu-semantic-colors-primary-purple-400: #381070;
  --emu-semantic-colors-primary-purple-500: #46157a;
  --emu-semantic-colors-primary-purple-600: #46107d;
  --emu-semantic-colors-primary-purple-700: #541985;
  --emu-semantic-colors-primary-purple-800: #671f93;
  --emu-semantic-colors-primary-purple-900: #75249e;
  --emu-semantic-colors-primary-purple-1000: #472e7f;
  --emu-semantic-colors-primary-purple-1100: #5e3daa;
  --emu-semantic-colors-primary-purple-1200: #764cd4;
  --emu-semantic-colors-primary-purple-1300: #7d06f3;
  --emu-semantic-colors-primary-pink-100: #8629a8;
  --emu-semantic-colors-primary-pink-200: #992fb6;
  --emu-semantic-colors-primary-pink-300: #962ea3;
  --emu-semantic-colors-primary-pink-400: #aa33c1;
  --emu-semantic-colors-primary-pink-500: #bb38cc;
  --emu-semantic-colors-primary-pink-600: #bc40c6;
  --emu-semantic-colors-secondary-light-purple-100: #e4dbf6;
  --emu-semantic-colors-secondary-light-gray-100: #f4f6f9;
  --emu-semantic-colors-secondary-dark-gray-100: #44484b;
  --emu-semantic-colors-secondary-dark-gray-200: #5f6062;
  --emu-semantic-colors-secondary-dark-gray-300: #181818;
  --emu-semantic-colors-secondary-dark-gray-400: #bec0c4;
  --emu-semantic-font-weight-500: 500;
  --emu-semantic-font-weight-bold: var(--emu-common-font-weight-bold);
  --emu-semantic-font-weight-light: var(--emu-common-font-weight-light);
  --emu-semantic-font-weight-black: var(--emu-common-font-weight-black);
  --emu-semantic-font-weight-regular: var(--emu-common-font-weight-regular);
  --emu-semantic-font-families-body: Gilroy;
  --emu-semantic-font-families-heading: Gilroy;
  --emu-semantic-font-families-mono: Gilroy;
  --emu-semantic-spacing-none: var(--emu-common-spacing-none);
  --emu-semantic-spacing-horizontal: var(--emu-common-spacing-none);
  --emu-semantic-spacing-vertical: var(--emu-common-spacing-none);
  --emu-semantic-spacing-one-line-height-wide: var(--emu-semantic-line-heights-wide-medium);
  --emu-semantic-spacing-one-line-height-narrow: var(--emu-semantic-line-heights-narrow-medium);
  --emu-semantic-font-sizes-narrow-medium: 18px;
  --emu-semantic-font-sizes-narrow-large: 22px;
  --emu-semantic-font-sizes-narrow-xl: 26px;
  --emu-semantic-font-sizes-narrow-xxl: 22px;
  --emu-semantic-font-sizes-narrow-xxxl: 37px;
  --emu-semantic-font-sizes-wide-medium: 20px;
  --emu-semantic-font-sizes-wide-large: 22px;
  --emu-semantic-font-sizes-wide-xl: 35px;
  --emu-semantic-font-sizes-wide-xxl: 26px;
  --emu-semantic-font-sizes-wide-xxxl: 61px;
  --emu-semantic-line-heights-narrow-large: 20px;
  --emu-semantic-line-heights-narrow-medium: 30px;
  --emu-semantic-line-heights-narrow-xl: 30px;
  --emu-semantic-line-heights-narrow-xxl: 30px;
  --emu-semantic-line-heights-narrow-xxxl: 40px;
  --emu-semantic-line-heights-wide-large: 22px;
  --emu-semantic-line-heights-wide-medium: 30px;
  --emu-semantic-line-heights-wide-xl: 44px;
  --emu-semantic-line-heights-wide-xxl: 22px;
  --emu-semantic-line-heights-wide-xxxl: 66px;
  --emu-semantic-border-radius-xs: var(--emu-common-border-radius-xs);
  --emu-semantic-border-radius-small: var(--emu-common-border-radius-small);
  --emu-semantic-border-radius-medium: var(--emu-common-border-radius-medium);
  --emu-semantic-border-radius-large: var(--emu-common-border-radius-large);
  --emu-semantic-border-radius-none: var(--emu-common-border-radius-none);
  --emu-semantic-border-width-thin: var(--emu-common-border-width-thin);
  --emu-semantic-border-width-medium: var(--emu-common-border-width-medium);
  --emu-semantic-border-width-thick: var(--emu-common-border-width-thick);
  --emu-semantic-border-width-none: 0px;
  --emu-semantic-outline-width-thin: var(--emu-common-outline-width-thin);
  --emu-semantic-outline-width-medium: var(--emu-common-outline-width-medium);
  --emu-semantic-outline-width-thick: var(--emu-common-outline-width-thick);
  --emu-semantic-outline-width-unset: unset;
  --emu-semantic-sizing-none: var(--emu-common-sizing-none);
  --emu-semantic-sizing-xxs: var(--emu-common-sizing-xxs);
  --emu-semantic-sizing-xs: var(--emu-common-sizing-xs);
  --emu-semantic-sizing-small: var(--emu-common-sizing-small);
  --emu-semantic-sizing-medium: var(--emu-common-sizing-medium);
  --emu-semantic-sizing-large: var(--emu-common-sizing-large);
  --emu-semantic-sizing-xl: var(--emu-common-sizing-xl);
  --emu-semantic-sizing-xxl: var(--emu-common-sizing-xxl);
  --emu-semantic-sizing-breakpoints-small: 320px;
  --emu-semantic-sizing-breakpoints-medium: 768px;
  --emu-semantic-sizing-breakpoints-tablet: 992px;
  --emu-semantic-sizing-breakpoints-large: 1024px;
  --emu-semantic-sizing-breakpoints-x-large: 1200px;
  --emu-semantic-sizing-breakpoints-xx-large: 1440px;
  --emu-semantic-sizing-one-line-height-wide: var(--emu-semantic-line-heights-wide-medium);
  --emu-semantic-sizing-one-line-height-narrow: var(--emu-semantic-line-heights-narrow-medium);
  --emu-semantic-typography-narrow-headings-xxxl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-xxxl) / var(--emu-semantic-line-heights-narrow-xxxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-xxl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-xxl) / var(--emu-semantic-line-heights-narrow-xxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-xl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-xl) / var(--emu-semantic-line-heights-narrow-xl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-large: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-large) / var(--emu-semantic-line-heights-narrow-large) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-medium: var(--emu-semantic-font-weight-bold) var(--emu-semantic-font-sizes-narrow-medium) / var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-body-regular: var(--emu-semantic-font-weight-regular) 18px / 20px var(--emu-semantic-font-families-body);
  --emu-semantic-typography-wide-headings-xxxl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-xxxl) / var(--emu-semantic-line-heights-wide-xxxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-xxl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-xxl) / var(--emu-semantic-line-heights-wide-xxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-xl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-xl) / var(--emu-semantic-line-heights-wide-xl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-large: var(--emu-semantic-font-weight-regular) var(--emu-semantic-line-heights-wide-large) / var(--emu-semantic-font-sizes-wide-large) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-medium: var(--emu-semantic-font-weight-bold) var(--emu-semantic-font-sizes-wide-medium) / var(--emu-semantic-line-heights-wide-medium) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-body-regular: var(--emu-semantic-font-weight-regular) 18px / 20px var(--emu-semantic-font-families-body);
  --emu-component-cards-card-margin-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-padding-top-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-card-padding-top-wide: var(--emu-common-spacing-none);
  --emu-component-cards-card-padding-right-narrow: var(--emu-common-spacing-small);
  --emu-component-cards-card-padding-right-wide: var(--emu-common-spacing-small);
  --emu-component-cards-card-padding-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-card-padding-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-cards-card-padding-left-narrow: var(--emu-common-spacing-small);
  --emu-component-cards-card-padding-left-wide: var(--emu-common-spacing-small);
  --emu-component-cards-card-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-cards-card-header-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-cards-card-header-button-text-color-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-size-height: 30px;
  --emu-component-cards-card-header-button-icon-size-width: 30px;
  --emu-component-cards-card-header-button-icon-spacing: var(--emu-common-spacing-xs);
  --emu-component-cards-card-header-button-icon-color-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-cards-card-header-button-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-body-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-cards-card-body-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-cards-teaser-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-cards-teaser-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-cards-teaser-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-cards-teaser-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-cards-teaser-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-cards-teaser-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-cards-teaser-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-cards-teaser-padding-left-wide: var(--emu-common-sizing-small);
  --emu-component-cards-teaser-text-color-light: var(--emu-common-colors-white);
  --emu-component-cards-teaser-text-color-dark: var(--emu-common-colors-black);
  --emu-component-cards-teaser-title-max-width: 66.6667%;
  --emu-component-cards-teaser-title-margin-top-narrow: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-title-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-title-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-title-margin-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-top-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-top-wide: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-lists-accordion-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-lists-accordion-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-accordion-color-text-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-accordion-color-text-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-accordion-border-radius-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-radius-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-radius-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-radius-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-margin-top-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-top-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-right-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-right-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-bottom-narrow: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-margin-bottom-wide: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-margin-left-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-left-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-color-border-bottom-closed-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-lists-accordion-item-color-border-bottom-closed-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-lists-accordion-item-color-border-bottom-open-dark: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-color-border-bottom-open-light: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-body-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-body-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-body-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-lists-accordion-item-body-padding-top-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-top-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-right-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-right-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-bottom-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-bottom-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-left-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-left-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-lists-accordion-item-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-lists-accordion-item-header-padding-top-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-top-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-right-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-right-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-bottom-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-bottom-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-left-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-left-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-icon-spacing: var(--emu-common-spacing-medium);
  --emu-component-lists-accordion-item-header-icon-size-height: 12px;
  --emu-component-lists-accordion-item-header-icon-size-width: 12px;
  --emu-component-lists-accordion-item-header-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-lists-accordion-item-header-icon-border-radius-top-left: var(--emu-semantic-border-radius-small);
  --emu-component-lists-accordion-item-header-icon-border-radius-top-right: var(--emu-semantic-border-radius-small);
  --emu-component-lists-accordion-item-header-icon-border-radius-bottom-right: var(--emu-semantic-border-radius-small);
  --emu-component-lists-accordion-item-header-icon-border-radius-bottom-left: var(--emu-semantic-border-radius-small);
  --emu-component-lists-accordion-item-header-icon-transform-open: rotate(0);
  --emu-component-lists-accordion-item-header-icon-transform-closed: rotate(180deg);
  --emu-component-lists-accordion-item-header-icon-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-lists-accordion-item-header-icon-color-fill-open-light: var(--emu-component-ingredients-icon-color-fill-light);
  --emu-component-lists-accordion-item-header-icon-color-fill-open-dark: var(--emu-component-ingredients-icon-color-fill-dark);
  --emu-component-lists-accordion-item-header-icon-color-fill-closed-light: var(--emu-component-ingredients-icon-color-fill-light);
  --emu-component-lists-accordion-item-header-icon-color-fill-closed-dark: var(--emu-component-ingredients-icon-color-fill-dark);
  --emu-component-lists-accordion-item-header-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-header-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-border-width-bottom-open: var(--emu-semantic-border-width-thin);
  --emu-component-lists-navigation-banner-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-banner-color-background-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-background-default-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-banner-item-color-background-default-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-background-hover-light: var(--emu-semantic-colors-none);
  --emu-component-lists-navigation-banner-item-color-background-hover-dark: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-color-border-default-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-banner-item-color-border-default-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-border-hover-light: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-color-border-hover-dark: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-text-color-default-light: var(--emu-semantic-colors-primary-purple-1100);
  --emu-component-lists-navigation-banner-item-text-color-default-dark: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-banner-item-text-color-hover-light: var(--emu-semantic-colors-primary-purple-1100);
  --emu-component-lists-navigation-banner-item-text-color-hover-dark: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-banner-item-border-width: var(--emu-common-border-width-none);
  --emu-component-lists-navigation-banner-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-dropdown-color-background-light: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-color-background-dark: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-color-border-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-color-border-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-border-width: var(--emu-common-border-width-thin);
  --emu-component-lists-navigation-dropdown-item-color-background-default-light: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-item-color-background-default-dark: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-item-color-background-hover-light: var(--emu-common-colors-grey-100);
  --emu-component-lists-navigation-dropdown-item-color-background-hover-dark: var(--emu-common-colors-grey-100);
  --emu-component-lists-navigation-dropdown-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-item-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-item-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-z-index: 500;
  --emu-component-lists-navigation-text-padding-top-narrow: 15px;
  --emu-component-lists-navigation-text-padding-top-wide: 15px;
  --emu-component-lists-navigation-text-padding-right-narrow: var(--emu-common-spacing-small);
  --emu-component-lists-navigation-text-padding-right-wide: var(--emu-common-spacing-small);
  --emu-component-lists-navigation-text-padding-bottom-narrow: 15px;
  --emu-component-lists-navigation-text-padding-bottom-wide: 15px;
  --emu-component-lists-navigation-text-padding-left-narrow: var(--emu-common-spacing-small);
  --emu-component-lists-navigation-text-padding-left-wide: var(--emu-common-spacing-small);
  --emu-component-feeds-instagram-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-bottom-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-feeds-instagram-padding-bottom-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-feeds-instagram-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-post-gap-narrow: 40px;
  --emu-component-feeds-instagram-post-gap-wide: 40px;
  --emu-component-ingredients-icon-color-fill-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-icon-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-icon-size-height: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-icon-size-width: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-separator-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-separator-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-separator-size-height: var(--emu-semantic-sizing-xxs);
  --emu-component-ingredients-title-h1-typography-narrow: var(--emu-semantic-typography-narrow-headings-xxxl);
  --emu-component-ingredients-title-h1-typography-wide: var(--emu-semantic-typography-wide-headings-xxxl);
  --emu-component-ingredients-title-h2-typography-narrow: var(--emu-semantic-typography-narrow-headings-xxl);
  --emu-component-ingredients-title-h2-typography-wide: var(--emu-semantic-typography-wide-headings-xxl);
  --emu-component-ingredients-title-h3-typography-narrow: var(--emu-semantic-typography-narrow-headings-xl);
  --emu-component-ingredients-title-h3-typography-wide: var(--emu-semantic-typography-wide-headings-xl);
  --emu-component-ingredients-title-h4-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-ingredients-title-h4-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-ingredients-title-h5-typography-narrow: var(--emu-semantic-typography-narrow-headings-medium);
  --emu-component-ingredients-title-h5-typography-wide: var(--emu-semantic-typography-wide-headings-medium);
  --emu-component-ingredients-title-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-title-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-text-typography-narrow: var(--emu-semantic-typography-narrow-body-regular);
  --emu-component-ingredients-text-text-typography-wide: var(--emu-semantic-typography-wide-body-regular);
  --emu-component-ingredients-loader-color-fill-light: var(--emu-semantic-colors-primary-pink-500);
  --emu-component-ingredients-loader-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-image-map-buttons-size-height: 27px;
  --emu-component-ingredients-image-map-buttons-size-width: 27px;
  --emu-component-ingredients-image-map-buttons-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-image-map-buttons-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-image-map-buttons-color-icon-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-ingredients-image-map-buttons-color-icon-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-ingredients-image-map-buttons-border-radius-top-left: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-top-right: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-bottom-right: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-bottom-left: 50%;
  --emu-component-ingredients-image-map-buttons-shadow: 0 0 2 5 #0009;
  --emu-component-ingredients-google-map-color-background-light: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-ingredients-google-map-color-background-dark: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-ingredients-google-map-margin-top-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-ingredients-google-map-margin-top-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-ingredients-google-map-margin-right-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-ingredients-google-map-margin-right-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-ingredients-google-map-margin-bottom-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-ingredients-google-map-margin-bottom-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-ingredients-google-map-margin-left-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-ingredients-google-map-margin-left-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-text-typography-narrow: var(--emu-semantic-font-weight-bold) var(--emu-semantic-font-sizes-narrow-medium) / var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-body);
  --emu-component-actions-button-text-typography-wide: var(--emu-semantic-font-weight-bold) 20 / 18 var(--emu-semantic-font-families-body);
  --emu-component-actions-button-text-color-link-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-link-dark: var(--emu-common-colors-black);
  --emu-component-actions-button-text-color-link-hover-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-link-hover-dark: var(--emu-common-colors-black);
  --emu-component-actions-button-text-color-primary-filled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-outline-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-outline-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-filled-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-filled-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-outline-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-outline-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-outline-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-component-actions-button-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-component-actions-button-padding-right-narrow: var(--emu-common-spacing-large);
  --emu-component-actions-button-padding-right-wide: var(--emu-common-spacing-large);
  --emu-component-actions-button-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-component-actions-button-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-component-actions-button-padding-left-narrow: var(--emu-common-spacing-large);
  --emu-component-actions-button-padding-left-wide: var(--emu-common-spacing-large);
  --emu-component-actions-button-border-width-primary-filled: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-primary-outline: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-secondary-filled: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-secondary-outline: var(--emu-common-border-width-thin);
  --emu-component-actions-button-outline-width-primary-filled: 0px;
  --emu-component-actions-button-outline-width-primary-outline: var(--emu-semantic-outline-width-thin);
  --emu-component-actions-button-outline-width-secondary-filled: var(--emu-semantic-outline-width-thin);
  --emu-component-actions-button-outline-width-secondary-outline: var(--emu-semantic-outline-width-thin);
  --emu-component-actions-button-border-radius-primary-filled-top-left: var(--emu-semantic-border-radius-small);
  --emu-component-actions-button-border-radius-primary-filled-top-right: var(--emu-semantic-border-radius-small);
  --emu-component-actions-button-border-radius-primary-filled-bottom-right: var(--emu-semantic-border-radius-small);
  --emu-component-actions-button-border-radius-primary-filled-bottom-left: var(--emu-semantic-border-radius-small);
  --emu-component-actions-button-border-radius-primary-outline-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-primary-outline-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-primary-outline-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-primary-outline-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-filled-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-filled-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-filled-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-filled-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-outline-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-outline-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-outline-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-outline-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-color-border-primary-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-primary-filled-dark: var(--emu-semantic-colors-primary-pink-300);
  --emu-component-actions-button-color-border-primary-filled-focus-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-primary-filled-focus-dark: var(--emu-semantic-colors-primary-pink-300);
  --emu-component-actions-button-color-border-primary-filled-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-primary-filled-hover-dark: var(--emu-semantic-colors-primary-pink-300);
  --emu-component-actions-button-color-border-primary-filled-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-primary-filled-active-dark: var(--emu-semantic-colors-primary-pink-300);
  --emu-component-actions-button-color-border-primary-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-primary-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-primary-outline-focus-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-primary-outline-focus-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-primary-outline-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-primary-outline-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-primary-outline-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-primary-outline-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-filled-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-filled-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-filled-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-filled-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-filled-hover-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-filled-hover-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-filled-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-filled-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-outline-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-outline-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-outline-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-outline-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-outline-hover-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-outline-hover-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-outline-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-outline-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-primary-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-primary-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-primary-filled-focus-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-primary-filled-focus-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-primary-filled-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-primary-filled-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-primary-filled-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-primary-filled-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-primary-outline-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-focus-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-focus-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-hover-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-primary-outline-hover-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-primary-outline-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-primary-outline-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-secondary-filled-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-secondary-filled-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-secondary-filled-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-secondary-filled-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-secondary-filled-hover-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-secondary-filled-hover-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-secondary-filled-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-secondary-filled-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-secondary-outline-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-focus-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-focus-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-hover-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-secondary-outline-hover-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-secondary-outline-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-secondary-outline-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-outline-color-primary-filled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-active-light: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-primary-filled-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-primary-outline-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-active-light: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-primary-outline-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-secondary-filled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-active-light: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-secondary-filled-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-secondary-outline-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-active-light: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-secondary-outline-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-group-horizontal-gap: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-horizontal-padding-left: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-horizontal-padding-right: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-horizontal-padding-top: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-button-group-horizontal-padding-bottom: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-button-group-vertical-gap: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-vertical-padding-left: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-vertical-padding-right: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-vertical-padding-top: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-button-group-vertical-padding-bottom: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-button-icon-size-height: 12px;
  --emu-component-actions-button-icon-size-width: 12px;
  --emu-component-actions-button-icon-spacing: var(--emu-common-spacing-xs);
  --emu-component-actions-button-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-actions-button-icon-border-radius-top-left: var(--emu-semantic-border-radius-small);
  --emu-component-actions-button-icon-border-radius-top-right: var(--emu-semantic-border-radius-small);
  --emu-component-actions-button-icon-border-radius-bottom-right: var(--emu-semantic-border-radius-small);
  --emu-component-actions-button-icon-border-radius-bottom-left: var(--emu-semantic-border-radius-small);
  --emu-component-actions-button-icon-transform-open: rotate(0);
  --emu-component-actions-button-icon-transform-closed: rotate(180deg);
  --emu-component-actions-button-icon-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-actions-button-icon-color-primary-filled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-outline-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-outline-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-back-to-top-z-index: 600;
  --emu-component-actions-back-to-top-icon-size-height: var(--emu-common-sizing-medium);
  --emu-component-actions-back-to-top-icon-size-width: var(--emu-common-sizing-medium);
  --emu-component-actions-back-to-top-position-bottom-narrow: 139px;
  --emu-component-actions-back-to-top-position-bottom-wide: 139px;
  --emu-component-actions-back-to-top-position-left-narrow: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-left-wide: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-right-narrow: var(--emu-common-spacing-small);
  --emu-component-actions-back-to-top-position-right-wide: var(--emu-common-spacing-medium);
  --emu-component-actions-back-to-top-position-top-narrow: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-top-wide: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-margin-bottom-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-bottom-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-left-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-left-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-right-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-right-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-top-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-top-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-containers-scroll-reveal-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-containers-scroll-reveal-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-containers-scroll-reveal-header-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-header-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-padding-top-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-scroll-reveal-padding-top-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-scroll-reveal-padding-bottom-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-scroll-reveal-padding-bottom-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-scroll-reveal-progress-bar-color-progress-indicator-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-progress-bar-color-progress-indicator-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-progress-bar-color-bar-light: var(--emu-common-colors-grey-300);
  --emu-component-containers-scroll-reveal-progress-bar-color-bar-dark: var(--emu-common-colors-grey-300);
  --emu-component-containers-scroll-reveal-static-section-margin-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-right-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-scroll-reveal-static-section-margin-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-scroll-reveal-cards-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-medium);
  --emu-component-containers-scroll-reveal-cards-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-medium);
  --emu-component-containers-scroll-reveal-cards-header-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-cards-header-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-cards-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-containers-scroll-reveal-cards-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-containers-scroll-reveal-cards-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-cards-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-comparison-slider-divider-color-light: var(--emu-common-colors-white);
  --emu-component-containers-comparison-slider-divider-color-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-comparison-slider-divider-size-width-narrow: 2px;
  --emu-component-containers-comparison-slider-divider-size-width-wide: 2px;
  --emu-component-containers-comparison-slider-handle-size-width-narrow: 40px;
  --emu-component-containers-comparison-slider-handle-size-width-wide: var(--emu-common-sizing-large);
  --emu-component-containers-comparison-slider-custom-handle-padding-top-narrow: 14px;
  --emu-component-containers-comparison-slider-custom-handle-padding-top-wide: 14px;
  --emu-component-containers-comparison-slider-custom-handle-padding-right-narrow: 5px;
  --emu-component-containers-comparison-slider-custom-handle-padding-right-wide: 5px;
  --emu-component-containers-comparison-slider-custom-handle-padding-bottom-narrow: 14px;
  --emu-component-containers-comparison-slider-custom-handle-padding-bottom-wide: 14px;
  --emu-component-containers-comparison-slider-custom-handle-padding-left-narrow: 5px;
  --emu-component-containers-comparison-slider-custom-handle-padding-left-wide: 5px;
  --emu-component-containers-comparison-slider-custom-handle-arrow-size-width-narrow: var(--emu-common-sizing-small);
  --emu-component-containers-comparison-slider-custom-handle-arrow-size-width-wide: var(--emu-common-sizing-small);
  --emu-component-containers-comparison-slider-custom-handle-arrow-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-comparison-slider-custom-handle-arrow-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-comparison-slider-custom-handle-arrow-thickness-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-containers-comparison-slider-custom-handle-arrow-thickness-wide: var(--emu-semantic-border-width-thin);
  --emu-component-containers-comparison-slider-custom-handle-color-background-light: var(--emu-semantic-colors-primary-pink-500);
  --emu-component-containers-comparison-slider-custom-handle-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-comparison-slider-custom-handle-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-comparison-slider-custom-handle-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-comparison-slider-custom-handle-text-font-size-narrow: var(--emu-semantic-font-sizes-narrow-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-font-size-wide: var(--emu-semantic-font-sizes-wide-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-line-height-narrow: var(--emu-semantic-line-heights-narrow-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-line-height-wide: var(--emu-semantic-line-heights-wide-medium);
  --emu-component-containers-tabs-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-gap-vertical-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-gap-vertical-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-navigation-item-padding-top-narrow: var(--emu-component-containers-container-padding-top-narrow);
  --emu-component-containers-tabs-navigation-item-padding-top-wide: var(--emu-component-containers-container-padding-top-wide);
  --emu-component-containers-tabs-navigation-item-padding-right-narrow: var(--emu-component-containers-container-padding-right-narrow);
  --emu-component-containers-tabs-navigation-item-padding-right-wide: var(--emu-component-containers-container-padding-right-wide);
  --emu-component-containers-tabs-navigation-item-padding-bottom-narrow: var(--emu-component-containers-container-padding-bottom-narrow);
  --emu-component-containers-tabs-navigation-item-padding-bottom-wide: var(--emu-component-containers-container-padding-bottom-wide);
  --emu-component-containers-tabs-navigation-item-padding-left-narrow: var(--emu-component-containers-container-padding-left-narrow);
  --emu-component-containers-tabs-navigation-item-padding-left-wide: var(--emu-component-containers-container-padding-left-wide);
  --emu-component-containers-tabs-navigation-item-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-vertical-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-vertical-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-navigation-item-border-width-top-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-top-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-right-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-right-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-bottom-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-bottom-active: var(--emu-semantic-border-width-thin);
  --emu-component-containers-tabs-navigation-item-border-width-left-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-left-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-color-border-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-border-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-border-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-border-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-background-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-background-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-tabs-navigation-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-tabs-navigation-item-text-color-active-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-tabs-navigation-item-text-color-active-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-tabs-content-padding-top-narrow: var(--emu-component-containers-container-padding-top-narrow);
  --emu-component-containers-tabs-content-padding-top-wide: var(--emu-component-containers-container-padding-top-wide);
  --emu-component-containers-tabs-content-padding-right-narrow: var(--emu-component-containers-container-padding-right-narrow);
  --emu-component-containers-tabs-content-padding-right-wide: var(--emu-component-containers-container-padding-right-wide);
  --emu-component-containers-tabs-content-padding-bottom-narrow: var(--emu-component-containers-container-padding-bottom-narrow);
  --emu-component-containers-tabs-content-padding-bottom-wide: var(--emu-component-containers-container-padding-bottom-wide);
  --emu-component-containers-tabs-content-padding-left-narrow: var(--emu-component-containers-container-padding-left-narrow);
  --emu-component-containers-tabs-content-padding-left-wide: var(--emu-component-containers-container-padding-left-wide);
  --emu-component-containers-tabs-content-gap-horizontal-narrow: var(--emu-component-containers-container-gap-horizontal-narrow);
  --emu-component-containers-tabs-content-gap-horizontal-wide: var(--emu-component-containers-container-gap-horizontal-wide);
  --emu-component-containers-tabs-content-gap-vertical-narrow: var(--emu-component-containers-container-gap-vertical-narrow);
  --emu-component-containers-tabs-content-gap-vertical-wide: var(--emu-component-containers-container-gap-vertical-wide);
  --emu-component-containers-tabs-content-color-background-light: var(--emu-component-containers-container-color-background-light);
  --emu-component-containers-tabs-content-color-background-dark: var(--emu-component-containers-container-color-background-dark);
  --emu-component-containers-isi-header-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-header-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-header-color-border-top-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-header-color-border-top-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-header-border-width-top: var(--emu-semantic-border-width-thin);
  --emu-component-containers-isi-body-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-body-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-body-color-border-top-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-body-color-border-top-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-body-border-width-top: var(--emu-semantic-border-width-thin);
  --emu-component-containers-isi-open-content-size: 30vh;
  --emu-component-containers-isi-z-index: 400;
  --emu-component-containers-container-max-width: 100%px;
  --emu-component-containers-container-padding-top-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-containers-container-padding-top-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-containers-container-padding-right-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-container-padding-right-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-container-padding-bottom-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-containers-container-padding-bottom-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-containers-container-padding-left-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-container-padding-left-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-container-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-container-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-container-gap-vertical-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-container-gap-vertical-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-container-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-containers-container-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-carousel-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-gap-vertical-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-gap-vertical-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-indicators-margin-top-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-indicators-margin-top-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-indicators-margin-bottom-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-indicators-margin-bottom-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-indicators-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-indicators-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-indicators-dots-color-background-default-light: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-dots-color-background-default-dark: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-dots-color-background-active-light: var(--emu-common-colors-grey-300);
  --emu-component-containers-carousel-indicators-dots-color-background-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-containers-carousel-indicators-dots-color-border-default-light: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-dots-color-border-default-dark: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-dots-color-border-active-light: var(--emu-common-colors-grey-300);
  --emu-component-containers-carousel-indicators-dots-color-border-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-containers-carousel-indicators-dots-border-width-all: var(--emu-common-border-width-thin);
  --emu-component-containers-carousel-indicators-dots-size-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-dots-size-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-image-container-size-narrow: 160px;
  --emu-component-containers-carousel-indicators-image-container-size-wide: 160px;
  --emu-component-containers-carousel-indicators-image-color-border-default-light: var(--emu-common-colors-grey-100);
  --emu-component-containers-carousel-indicators-image-color-border-default-dark: var(--emu-common-colors-grey-100);
  --emu-component-containers-carousel-indicators-image-color-border-active-light: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-image-color-border-active-dark: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-image-border-width-all: var(--emu-common-border-width-thick);
  --emu-component-containers-carousel-indicators-image-size-narrow: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-indicators-image-size-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-indicators-text-color-background-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-indicators-text-color-background-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-indicators-text-color-text-light: var(--emu-common-colors-black);
  --emu-component-containers-carousel-indicators-text-color-text-dark: var(--emu-common-colors-white);
  --emu-component-containers-carousel-indicators-text-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-font-size-narrow: var(--emu-semantic-font-sizes-narrow-medium);
  --emu-component-containers-carousel-indicators-text-font-size-wide: var(--emu-semantic-font-sizes-wide-medium);
  --emu-component-containers-carousel-controls-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-color-background-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-controls-color-background-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-controls-color-border-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-controls-color-border-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-controls-border-width-all: var(--emu-common-border-width-thin);
  --emu-component-containers-carousel-controls-z-index: 100;
  --emu-component-containers-carousel-controls-icon-color-fill-light: var(--emu-component-ingredients-icon-color-fill-light);
  --emu-component-containers-carousel-controls-icon-color-fill-dark: var(--emu-component-ingredients-icon-color-fill-dark);
  --emu-component-containers-carousel-controls-icon-size-narrow: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-controls-icon-size-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-embed-gap-horizontal-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-horizontal-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-vertical-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-vertical-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-embed-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-embed-player-vimeo-global-color-light: #00b3ff;
  --emu-component-containers-embed-player-vimeo-global-color-dark: #00b3ff;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-base: 15px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small: 13px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-large: 18px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-xlarge: 21px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-weight-regular: 400;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-weight-bold: 600;
  --emu-component-containers-embed-player-vimeo-global-text-typography-line-height: 1.7px;
  --emu-component-containers-embed-player-vimeo-color-background-light: #000;
  --emu-component-containers-embed-player-vimeo-color-background-dark: #000;
  --emu-component-containers-embed-player-vimeo-outline-color-focus-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-outline-color-focus-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-badge-border-radius-all: 2px;
  --emu-component-containers-embed-player-vimeo-badge-color-background-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-badge-color-background-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-badge-text-typography-font-size: 9px;
  --emu-component-containers-embed-player-vimeo-badge-text-color-light: #fff;
  --emu-component-containers-embed-player-vimeo-badge-text-color-dark: #fff;
  --emu-component-containers-embed-player-vimeo-menu-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-menu-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-menu-color-border-light: #dcdfe5;
  --emu-component-containers-embed-player-vimeo-menu-color-border-dark: #dcdfe5;
  --emu-component-containers-embed-player-vimeo-menu-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-size: 4px;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-color-light: #728197;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-color-dark: #728197;
  --emu-component-containers-embed-player-vimeo-menu-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-vimeo-menu-text-color-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-menu-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-menu-border-radius-all: 4px;
  --emu-component-containers-embed-player-vimeo-menu-arrow-size: 6px;
  --emu-component-containers-embed-player-vimeo-controls-global-spacing: 10px;
  --emu-component-containers-embed-player-vimeo-controls-global-icon-sizing: 18px;
  --emu-component-containers-embed-player-vimeo-controls-global-padding: 7px;
  --emu-component-containers-embed-player-vimeo-controls-global-toggle-color-background-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-global-toggle-color-background-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-global-border-radius-all: 3px;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-tooltip-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-tooltip-text-color-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-vimeo-tooltip-border-radius-all: 3px;
  --emu-component-containers-embed-player-vimeo-tooltip-padding: 5px;
  --emu-component-containers-embed-player-vimeo-tooltip-arrow-size: 4px;
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-active-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-active-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-loading-light: #23282f99;
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-loading-dark: #23282f99;
  --emu-component-containers-embed-player-vimeo-scrubber-sizing-height: 5px;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-sizing-height: 13px;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-color-background-light: #fff;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-color-background-dark: #fff;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-one: 0 1 1 0 #d71a1226;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-two: 0 0 0 1 #d71a1233;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-width-active: 3px;
  --emu-component-containers-embed-player-vimeo-scrubber-loading-size: 25px;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-progress-color-background-buffered-light: #c1c8d199;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-progress-color-background-buffered-dark: #c1c8d199;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-thumb-shadow-active-light: #d71a121a;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-thumb-shadow-active-dark: #d71a121a;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-progress-color-background-buffered-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-progress-color-background-buffered-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-thumb-shadow-active-light: #ffffff80;
  --emu-component-containers-embed-player-vimeo-scrubber-video-thumb-shadow-active-dark: #ffffff80;
  --emu-component-containers-embed-player-vimeo-scrubber-video-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-captions-text-color-light: #fff;
  --emu-component-containers-embed-player-vimeo-captions-text-color-dark: #fff;
  --emu-component-containers-embed-player-vimeo-captions-color-background-light: #000c;
  --emu-component-containers-embed-player-vimeo-captions-color-background-dark: #000c;
  --emu-component-containers-embed-player-vimeo-time-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-youtube-global-color-light: #00b3ff;
  --emu-component-containers-embed-player-youtube-global-color-dark: #00b3ff;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-base: 15px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-small: 13px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-large: 18px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-xlarge: 21px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-weight-regular: 400;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-weight-bold: 600;
  --emu-component-containers-embed-player-youtube-global-text-typography-line-height: 1.7px;
  --emu-component-containers-embed-player-youtube-color-background-light: #000;
  --emu-component-containers-embed-player-youtube-color-background-dark: #000;
  --emu-component-containers-embed-player-youtube-outline-color-focus-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-outline-color-focus-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-badge-border-radius-all: 2px;
  --emu-component-containers-embed-player-youtube-badge-color-background-light: #4a5464;
  --emu-component-containers-embed-player-youtube-badge-color-background-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-badge-text-typography-font-size: 9px;
  --emu-component-containers-embed-player-youtube-badge-text-color-light: #fff;
  --emu-component-containers-embed-player-youtube-badge-text-color-dark: #fff;
  --emu-component-containers-embed-player-youtube-menu-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-youtube-menu-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-youtube-menu-color-border-light: #dcdfe5;
  --emu-component-containers-embed-player-youtube-menu-color-border-dark: #dcdfe5;
  --emu-component-containers-embed-player-youtube-menu-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-size: 4px;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-color-light: #728197;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-color-dark: #728197;
  --emu-component-containers-embed-player-youtube-menu-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-youtube-menu-text-color-light: #4a5464;
  --emu-component-containers-embed-player-youtube-menu-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-menu-border-radius-all: 4px;
  --emu-component-containers-embed-player-youtube-menu-arrow-size: 6px;
  --emu-component-containers-embed-player-youtube-controls-global-spacing: 10px;
  --emu-component-containers-embed-player-youtube-controls-global-icon-sizing: 18px;
  --emu-component-containers-embed-player-youtube-controls-global-padding: 7px;
  --emu-component-containers-embed-player-youtube-controls-global-toggle-color-background-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-global-toggle-color-background-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-global-border-radius-all: 3px;
  --emu-component-containers-embed-player-youtube-controls-video-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-youtube-controls-video-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-youtube-controls-video-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-video-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-light: #4a5464;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-youtube-tooltip-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-youtube-tooltip-text-color-light: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-youtube-tooltip-border-radius-all: 3px;
  --emu-component-containers-embed-player-youtube-tooltip-padding: 5px;
  --emu-component-containers-embed-player-youtube-tooltip-arrow-size: 4px;
  --emu-component-containers-embed-player-youtube-scrubber-color-background-active-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-scrubber-color-background-active-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-scrubber-color-background-loading-light: #23282f99;
  --emu-component-containers-embed-player-youtube-scrubber-color-background-loading-dark: #23282f99;
  --emu-component-containers-embed-player-youtube-scrubber-sizing-height: 5px;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-sizing-height: 13px;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-color-background-light: #fff;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-color-background-dark: #fff;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-one: 0 1 1 0 #d71a1226;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-two: 0 0 0 1 #d71a1233;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-width-active: 3px;
  --emu-component-containers-embed-player-youtube-scrubber-loading-size: 25px;
  --emu-component-containers-embed-player-youtube-scrubber-audio-progress-color-background-buffered-light: #c1c8d199;
  --emu-component-containers-embed-player-youtube-scrubber-audio-progress-color-background-buffered-dark: #c1c8d199;
  --emu-component-containers-embed-player-youtube-scrubber-audio-thumb-shadow-active-light: #d71a121a;
  --emu-component-containers-embed-player-youtube-scrubber-audio-thumb-shadow-active-dark: #d71a121a;
  --emu-component-containers-embed-player-youtube-scrubber-audio-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-audio-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-progress-color-background-buffered-light: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-progress-color-background-buffered-dark: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-thumb-shadow-active-light: #ffffff80;
  --emu-component-containers-embed-player-youtube-scrubber-video-thumb-shadow-active-dark: #ffffff80;
  --emu-component-containers-embed-player-youtube-scrubber-video-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-youtube-captions-text-color-light: #fff;
  --emu-component-containers-embed-player-youtube-captions-text-color-dark: #fff;
  --emu-component-containers-embed-player-youtube-captions-color-background-light: #000c;
  --emu-component-containers-embed-player-youtube-captions-color-background-dark: #000c;
  --emu-component-containers-embed-player-youtube-time-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-gallery-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-border-width: var(--emu-semantic-border-width-thin);
  --emu-component-containers-gallery-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-separator-content-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-sizing-width-narrow: 100%;
  --emu-component-containers-gallery-item-sizing-width-wide: calc(33.33% - 8px);
  --emu-component-containers-gallery-item-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-border-width: var(--emu-semantic-border-width-thin);
  --emu-component-containers-gallery-item-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-stage-container-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-gap-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-preview-container-gap-wide: var(--emu-common-sizing-xs);
  --emu-component-layers-modal-z-index: 600;
  --emu-component-layers-modal-scrim-color-background: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-layers-modal-body-sizing-width: 80%;
  --emu-component-layers-modal-body-sizing-max-width: 800px;
  --emu-component-layers-modal-body-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-color-background-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-body-color-background-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-body-color-border-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-body-color-border-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-body-border-width-all: var(--emu-semantic-border-width-thin);
  --emu-component-layers-modal-actions-close-padding-top: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-padding-right: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-padding-bottom: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-padding-left: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-text-typography: var(--emu-semantic-typography-narrow-headings-xxl);
  --emu-component-layers-modal-actions-close-text-color-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-actions-close-text-color-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-actions-prompt-button-group-gap-narrow: var(--emu-common-spacing-small);
  --emu-component-layers-modal-actions-prompt-button-group-gap-wide: var(--emu-common-spacing-small);
  --emu-component-layers-modal-actions-prompt-button-group-margin-top: var(--emu-semantic-spacing-horizontal);
  --emu-component-layers-cookie-consent-banner-z-index: 700;
  --emu-component-layers-cookie-consent-banner-max-width: 1450px;
  --emu-component-layers-cookie-consent-banner-text-typography-narrow: var(--emu-semantic-font-weight-regular) 12px / 17px var(--emu-semantic-font-families-heading);
  --emu-component-layers-cookie-consent-banner-text-typography-wide: var(--emu-semantic-font-weight-regular) 12px / 17px var(--emu-semantic-font-families-heading);
  --emu-component-layers-cookie-consent-banner-text-color-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-text-color-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-color-background-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-color-background-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-shadow: 4px 4px 20px 4px #0003;
  --emu-component-layers-cookie-consent-banner-padding-top-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-padding-top-wide: 24px;
  --emu-component-layers-cookie-consent-banner-padding-right-narrow: 32px;
  --emu-component-layers-cookie-consent-banner-padding-right-wide: 32px;
  --emu-component-layers-cookie-consent-banner-padding-bottom-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-padding-bottom-wide: 24px;
  --emu-component-layers-cookie-consent-banner-padding-left-narrow: 32px;
  --emu-component-layers-cookie-consent-banner-padding-left-wide: 32px;
  --emu-component-layers-cookie-consent-banner-settings-button-text-typography-narrow: var(--emu-semantic-font-weight-regular) 12px / 17px var(--emu-semantic-font-families-heading);
  --emu-component-layers-cookie-consent-banner-settings-button-text-typography-wide: var(--emu-semantic-font-weight-regular) 12px / 17px var(--emu-semantic-font-families-heading);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-focus-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-focus-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-hover-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-hover-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-active-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-active-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-padding-top-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-top-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-right-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-right-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-bottom-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-bottom-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-left-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-left-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-top-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-top-wide: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-right-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-right-wide: 0px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-bottom-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-bottom-wide: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-left-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-left-wide: 56px;
  --emu-component-layers-cookie-consent-banner-settings-button-border-width: 1px;
  --emu-component-layers-cookie-consent-banner-settings-button-outline-width: 1px;
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-focus-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-focus-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-hover-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-hover-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-active-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-active-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-dark: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-focus-light: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-focus-dark: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-hover-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-hover-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-active-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-active-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-light: var(--emu-common-colors-grey-300);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-dark: var(--emu-common-colors-grey-300);
}

html {
  -webkit-text-size-adjust: 100%;
}

html, body {
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  font-family: var(--emu-semantic-font-families-body);
  text-align: left;
  margin: 0;
  padding: 0;
  line-height: 1.4;
}

html .xf-content-height, body .xf-content-height {
  margin: inherit;
}

html .experiencefragment, body .experiencefragment {
  padding: 0;
}

body {
  -webkit-overflow-scrolling: touch;
}

* {
  outline: var(--emu-semantic-colors-surface-dark);
}

*, :before, :after {
  box-sizing: border-box;
}

.cmp-image__image, image {
  max-width: 100%;
  width: auto;
}

button, input, optgroup, select, textarea {
  font: inherit;
}

.desktop-view {
  display: none;
}

@media (min-width: 1024px) {
  .desktop-view {
    display: block;
  }
}

.tablet-desktop-view {
  display: none;
}

@media (min-width: 1024px) {
  .tablet-desktop-view {
    display: block;
  }
}

.tablet-mobile-view {
  display: block;
}

@media (min-width: 1024px) {
  .tablet-mobile-view {
    display: none;
  }
}

.mobile-view {
  display: block;
}

@media (min-width: 1024px) {
  .mobile-view {
    display: none;
  }
}

.no-pad {
  padding: 0;
}

.no-x-pad {
  padding-left: 0;
  padding-right: 0;
}

.no-y-pad {
  padding-top: 0;
  padding-bottom: 0;
}

.content--centered {
  text-align: center;
}

.container--large {
  max-width: var(--emu-semantic-sizing-breakpoints-x-large);
  margin-left: auto;
  margin-right: auto;
}

.container--xl {
  max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
  margin-left: auto;
  margin-right: auto;
}

.bg--primary-purple-1000 {
  background-color: var(--emu-semantic-colors-primary-purple-1000);
}

.bg--mix-purple {
  background: linear-gradient(170deg, #06004d 0%, var(--emu-semantic-colors-primary-purple-200) 6%, var(--emu-semantic-colors-primary-purple-300) 14%, var(--emu-semantic-colors-primary-purple-400) 24%, var(--emu-semantic-colors-primary-purple-500) 35%, var(--emu-semantic-colors-primary-purple-700) 48%, var(--emu-semantic-colors-primary-purple-800) 61%, var(--emu-semantic-colors-primary-purple-900) 72%, var(--emu-semantic-colors-primary-pink-100) 82%, var(--emu-semantic-colors-primary-pink-200) 90%, var(--emu-semantic-colors-primary-pink-400) 96%, var(--emu-semantic-colors-primary-pink-500) 100%);
}

[data-component="text"][class*="text--color-"] h1, [data-component="text"][class*="text--color-"] h2, [data-component="text"][class*="text--color-"] h3, [data-component="text"][class*="text--color-"] h4, [data-component="text"][class*="text--color-"] h5, [data-component="text"][class*="text--color-"] h6, [data-component="title"][class*="text--color-"] h1, [data-component="title"][class*="text--color-"] h2, [data-component="title"][class*="text--color-"] h3, [data-component="title"][class*="text--color-"] h4, [data-component="title"][class*="text--color-"] h5, [data-component="title"][class*="text--color-"] h6 {
  color: inherit;
}

[data-component="text"][class*="font-"] h1, [data-component="text"][class*="font-"] h2, [data-component="text"][class*="font-"] h3, [data-component="text"][class*="font-"] h4, [data-component="text"][class*="font-"] h5, [data-component="text"][class*="font-"] h6, [data-component="text"][class*="font-"] p, [data-component="text"][class*="font-"] li, [data-component="title"][class*="font-"] h1, [data-component="title"][class*="font-"] h2, [data-component="title"][class*="font-"] h3, [data-component="title"][class*="font-"] h4, [data-component="title"][class*="font-"] h5, [data-component="title"][class*="font-"] h6, [data-component="title"][class*="font-"] p, [data-component="title"][class*="font-"] li {
  font-family: inherit;
}

[data-component="text"].text--color-white, [data-component="title"].text--color-white {
  color: var(--emu-common-colors-white);
}

[data-component="text"].text--color-black, [data-component="title"].text--color-black {
  color: var(--emu-common-colors-black);
}

[data-component="text"].text--color-pink, [data-component="title"].text--color-pink {
  color: var(--emu-semantic-colors-primary-pink-500);
}

[data-component="text"].text--purple, [data-component="title"].text--purple {
  color: var(--emu-semantic-colors-primary-purple-1100);
}

@media (min-width: 1024px) {
  [data-component="text"].text-primary {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
    line-height: var(--emu-semantic-line-heights-wide-large);
  }
}

[data-component="text"].text-secondary {
  font-size: var(--emu-common-font-sizes-narrow-medium);
  line-height: var(--emu-semantic-line-heights-narrow-large);
}

@media (min-width: 1024px) {
  [data-component="text"].text-secondary {
    font-size: 11px;
    line-height: var(--emu-semantic-line-heights-wide-large);
  }

  .container--spacing {
    padding-left: var(--emu-common-spacing-medium);
    padding-right: var(--emu-common-spacing-medium);
  }
}

@media (min-width: 1200px) {
  .container--spacing {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

.form-container {
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: var(--emu-common-spacing-small);
  margin-right: var(--emu-common-spacing-small);
  padding-left: var(--emu-common-spacing-medium);
  padding-right: var(--emu-common-spacing-medium);
  padding-top: var(--emu-common-spacing-small);
  border-radius: var(--emu-semantic-border-radius-small);
  border: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-primary-purple-1200);
  padding-bottom: 20px;
}

.form-container .hidden {
  display: none;
}

.form-container .cmp-ajax-form {
  position: relative;
}

.form-container .cmp-ajax-form .emu-loader {
  height: 100%;
  z-index: var(--emu-common-other-z-index-layer);
  background: #fff6;
  display: flex;
  position: absolute;
}

.form-container .cmp-ajax-form .emu-loader__indicator {
  margin-bottom: 80px;
}

@media (min-width: 1024px) {
  .form-container {
    padding-top: var(--emu-common-spacing-medium);
    padding-right: var(--emu-common-spacing-large);
    padding-left: var(--emu-common-spacing-large);
    margin-top: 84px;
    margin-bottom: 84px;
    padding-bottom: 22px;
  }
}

@media (min-width: 1200px) {
  .form-container {
    margin-left: auto;
    margin-right: auto;
  }
}

.form-container__btn-wrapper {
  grid-gap: 20px;
  flex-direction: column;
  gap: 20px;
  display: flex;
}

@media (min-width: 1024px) {
  .form-container__btn-wrapper {
    grid-gap: 22px;
    gap: 22px;
  }
}

.form-container__top {
  margin-bottom: 40px;
  scroll-margin-top: 80px;
}

@media (min-width: 1024px) {
  .form-container__top {
    margin-bottom: 44px;
  }
}

.form-container__top-title h2 {
  color: var(--emu-semantic-colors-primary-pink-600);
  font-weight: var(--emu-semantic-font-weight-bold);
}

@media (min-width: 1024px) {
  .form-container__top-title h2 {
    font-size: 26px;
    line-height: 33px;
  }
}

.form-container__top-subtitle h3 {
  padding-bottom: 20px;
}

@media (min-width: 1024px) {
  .form-container__top-subtitle h3 {
    font-size: var(--emu-semantic-font-sizes-wide-xl);
    line-height: var(--emu-semantic-line-heights-wide-xl);
    padding-bottom: 22px;
  }

  .form-container__top-text.aaaem-text {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
    line-height: var(--emu-semantic-line-heights-wide-large);
  }
}

.form-container__bottom-text[data-component="text"] > p {
  font-size: 12.5px;
}

@media (min-width: 1024px) {
  .form-container__bottom-text[data-component="text"] > p {
    line-height: var(--emu-semantic-line-heights-wide-large);
    font-size: 11.48px;
  }
}

.form-container__bottom-text[data-component="text"] > p a, .form-container__bottom-text[data-component="text"] > p a:hover, .form-container__bottom-text[data-component="text"] > p a:focus {
  color: var(--emu-common-colors-black);
}

.patient-testimonial {
  grid-row-gap: 15px;
  flex-direction: column;
  row-gap: 15px;
  display: flex;
}

.patient-testimonial__pl-8[data-component="container"] {
  padding-left: var(--emu-common-spacing-small);
}

.patient-testimonial img {
  max-width: var(--emu-common-sizing-medium);
}

.patient-testimonial .cmp-text p {
  line-height: var(--emu-semantic-line-heights-narrow-large);
  font-weight: var(--emu-common-font-weight-bold);
}

.patient-testimonial__text[data-component="text"] p {
  color: var(--emu-semantic-colors-primary-pink-500);
  font-size: 15px;
}

.patient-testimonial__name[data-component="text"] p {
  color: var(--emu-semantic-colors-secondary-dark-gray-200);
  font-size: 12.5px;
  font-weight: var(--emu-common-font-weight-bold);
}

.patient-testimonial__name[data-component="text"] h5 {
  font-weight: var(--emu-common-font-weight-bold);
  line-height: var(--emu-semantic-line-heights-narrow-large);
  font-size: 12.5px;
}

@media (min-width: 1024px) {
  .patient-testimonial__name[data-component="text"] h5 {
    font-weight: var(--emu-common-font-weight-regular);
  }
}

.patient-testimonial--white .cmp-text p {
  color: var(--emu-common-colors-white);
  line-height: va(--emu-semantic-line-heights-narrow-large);
  font-size: 15px;
  font-weight: var(--emu-common-font-weight-bold);
}

@media (min-width: 1024px) {
  .patient-testimonial--white .cmp-text p {
    line-height: var(--emu-semantic-line-heights-wide-large);
  }
}

.patient-testimonial--white .patient-testimonial__name[data-component="text"] p {
  font-weight: var(--emu-common-font-weight-regular);
  font-size: 12.5px;
}

@media (min-width: 1024px) {
  .patient-testimonial--white .patient-testimonial__name[data-component="text"] p {
    font-size: 11.5px;
  }
}

#home .hero-section__img img {
  width: 100%;
  height: auto;
}

#home .testimonials-container {
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (min-width: 1024px) {
  #home .testimonials-container {
    padding-top: 44px;
    padding-bottom: 44px;
  }
}

#home .testimonials-container .patient-testimonial {
  padding-left: var(--emu-common-spacing-large);
  padding-right: var(--emu-common-spacing-large);
}

#home .overview {
  flex-direction: column;
  display: flex;
}

@media (min-width: 1200px) {
  #home .overview {
    padding-left: var(--emu-common-spacing-small);
    padding-right: var(--emu-common-spacing-small);
  }
}

#home .overview__container {
  padding-top: 40px;
  padding-right: var(--emu-common-spacing-small);
  padding-bottom: 40px;
  padding-left: var(--emu-common-spacing-small);
}

#home .overview__title h2 {
  font-weight: var(--emu-common-font-weight-bold);
}

@media (min-width: 1024px) {
  #home .overview__title h2 {
    font-size: var(--emu-semantic-font-sizes-narrow-xl);
    line-height: 33px;
  }

  #home .overview__subtitle h3 {
    font-size: var(--emu-semantic-font-sizes-wide-xl);
    line-height: var(--emu-semantic-line-heights-wide-xl);
  }
}

#home .overview__subtitle h3 sup {
  top: -9px;
}

@media (min-width: 1024px) {
  #home .overview__subtitle h3 sup {
    top: -15px;
  }
}

#home .overview__text p {
  color: var(--emu-semantic-colors-secondary-dark-gray-300);
  margin-top: 20px;
}

@media (min-width: 1024px) {
  #home .overview__text p {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
    line-height: var(--emu-semantic-line-heights-wide-xxl);
  }
}

#home .overview__text p br {
  display: none;
}

@media (min-width: 1024px) {
  #home .overview__text p br {
    display: block;
  }
}

#home .overview__benefit {
  background-color: var(--emu-semantic-colors-secondary-light-gray-100);
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: var(--emu-common-sizing-small);
  padding-right: var(--emu-common-sizing-small);
}

@media (min-width: 1024px) {
  #home .overview__benefit {
    max-width: 1040px;
    grid-gap: 40px;
    background: var(--emu-common-colors-transparent);
    padding-top: var(--emu-common-spacing-none);
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 40px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 40px;
    display: flex;
  }
}

#home .overview__benefit-inner-container .cmp-text, #home .overview__benefit-inner-container .cmp-title, #home .overview__benefit-inner-container .cmp-image {
  text-align: center;
}

@media (min-width: 1024px) {
  #home .overview__benefit-inner-container .cmp-text, #home .overview__benefit-inner-container .cmp-title, #home .overview__benefit-inner-container .cmp-image {
    text-align: left;
  }
}

#home .overview__benefit > div.container {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: var(--emu-common-spacing-small);
  padding-right: var(--emu-common-spacing-small);
}

@media (min-width: 1024px) {
  #home .overview__benefit > div.container {
    width: calc(50% - 20px);
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    padding-top: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-none);
  }
}

#home .overview__benefit-image img {
  width: 48px;
  height: auto;
  display: inline-block;
}

@media (min-width: 1024px) {
  #home .overview__benefit-image img {
    width: var(--emu-common-sizing-large);
    margin-bottom: var(--emu-common-spacing-small);
  }
}

#home .overview__benefit-title h4 {
  font-weight: var(--emu-common-font-weight-bold);
  line-height: var(--emu-semantic-line-heights-narrow-medium);
  margin-bottom: 20px;
}

@media (min-width: 1024px) {
  #home .overview__benefit-title h4 {
    line-height: 33px;
    font-size: var(--emu-semantic-font-sizes-narrow-xl);
  }

  #home .overview__benefit-text p {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
    line-height: var(--emu-semantic-line-heights-wide-large);
    margin-bottom: var(--emu-common-spacing-small);
  }
}

#home .overview__disclaimer {
  background-color: var(--emu-semantic-colors-secondary-light-gray-100);
  padding-bottom: 20px;
}

#home .overview__disclaimer br {
  display: block;
}

@media (min-width: 1024px) {
  #home .overview__disclaimer br {
    display: none;
  }

  #home .overview__disclaimer {
    background-color: var(--emu-common-colors-transparent);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-bottom: 0;
  }
}

#home .overview__disclaimer p {
  font-size: 11.48px;
  line-height: 22px;
}

#home .provider {
  background-color: var(--emu-semantic-colors-primary-purple-1000);
}

@media (min-width: 1024px) {
  #home .provider {
    padding-top: 33px;
    padding-bottom: 33px;
    display: flex;
    position: relative;
  }
}

#home .provider__inner {
  padding-top: 44px;
  padding-right: var(--emu-common-spacing-large);
  padding-bottom: 36px;
  padding-left: var(--emu-common-spacing-large);
}

@media (min-width: 1024px) {
  #home .provider__inner {
    padding-top: var(--emu-common-spacing-large);
    padding-bottom: var(--emu-common-spacing-large);
  }
}

#home .provider > div:first-child:after {
  content: "";
  background-color: var(--emu-semantic-colors-primary-purple-1200);
  width: 92%;
  height: var(--emu-common-border-width-thin);
  margin-left: auto;
  margin-right: auto;
  display: block;
}

@media (min-width: 1024px) {
  #home .provider > div:first-child:after {
    display: none;
  }
}

#home .provider > div.container {
  position: relative;
}

@media (min-width: 1024px) {
  #home .provider > div.container {
    flex: 1;
    align-self: center;
  }
}

#home .provider__text h3 {
  font-size: var(--emu-semantic-font-sizes-narrow-xxl);
  font-weight: var(--emu-common-font-weight-bold);
}

@media (min-width: 1024px) {
  #home .provider__text h3 {
    font-size: var(--emu-semantic-font-sizes-wide-xxl);
    line-height: 33px;
  }
}

#home .provider__text h3 br {
  display: none;
}

@media (min-width: 1024px) {
  #home .provider__text h3 br {
    display: block;
  }
}

#home .provider__subtext p {
  margin-top: 20px;
}

@media (min-width: 1024px) {
  #home .provider__subtext p {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
    line-height: var(--emu-semantic-line-heights-wide-xxl);
    margin-top: 22px;
  }
}

#home .provider__button {
  font-weight: var(--emu-common-font-weight-bold);
  line-height: var(--emu-semantic-line-heights-narrow-large);
  margin-top: 40px;
}

@media (min-width: 1024px) {
  #home .provider__button {
    margin-top: 44px;
  }
}

#home .about {
  margin-top: 40px;
}

@media (min-width: 1240px) {
  #home .about .resonic-grid__text {
    padding: var(--emu-common-spacing-none);
  }
}

#home .about__content-wrapper {
  margin-bottom: 20px;
}

@media (min-width: 1024px) {
  #home .about__content-wrapper {
    grid-gap: 75px;
    flex-wrap: nowrap;
    gap: 75px;
    margin-bottom: 28px;
    display: flex;
  }

  #home .about__content-wrapper .image {
    display: none;
  }
}

#home .about__content-wrapper img {
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  #home .about__cards-wrapper {
    grid-gap: 75px;
    flex-wrap: nowrap;
    gap: 75px;
    display: flex;
  }
}

#home .about__card {
  grid-gap: var(--emu-common-spacing-large);
  gap: var(--emu-common-spacing-large);
  flex-direction: column;
  margin-bottom: 26px;
  display: flex;
}

@media (min-width: 1024px) {
  #home .about__card {
    margin-bottom: 20px;
  }
}

#home .about__card img {
  max-width: min(100%, 600px);
  margin-left: auto;
  margin-right: auto;
}

#home .about__tradeoffs {
  margin-top: 24px;
}

#home .resonic-grid__text {
  padding-left: var(--emu-common-spacing-small);
  padding-right: var(--emu-common-spacing-small);
}

#home .resonic-grid__text sup {
  font-family: sans-serif;
  top: -6px;
}

#home .resonic-grid__text h2 {
  font-weight: var(--emu-common-font-weight-bold);
  color: var(--emu-semantic-colors-primary-pink-500);
}

@media (min-width: 1024px) {
  #home .resonic-grid__text h2 {
    font-size: var(--emu-semantic-font-sizes-narrow-xl);
    line-height: 33px;
  }
}

#home .resonic-grid__text h3 {
  color: var(--emu-semantic-colors-primary-purple-1100);
}

@media (min-width: 1024px) {
  #home .resonic-grid__text h3 {
    font-size: var(--emu-semantic-font-sizes-wide-xl);
    line-height: var(--emu-semantic-line-heights-wide-xl);
  }
}

#home .resonic-grid__text h4 {
  color: var(--emu-semantic-colors-primary-pink-300);
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  line-height: var(--emu-semantic-line-heights-narrow-large);
  font-weight: var(--emu-common-font-weight-bold);
  margin-top: var(--emu-common-spacing-large);
}

@media (min-width: 1024px) {
  #home .resonic-grid__text h4 {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
    line-height: var(--emu-semantic-line-heights-wide-large);
  }
}

#home .resonic-grid__text h4 + p {
  margin-top: var(--emu-common-spacing-none);
}

#home .resonic-grid__text p {
  color: var(--emu-semantic-colors-secondary-dark-gray-300);
  line-height: var(--emu-semantic-line-heights-narrow-large);
  margin-top: 20px;
}

@media (min-width: 1024px) {
  #home .resonic-grid__text p:first-of-type {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
  }

  #home .resonic-grid__text p:not(:first-of-type) {
    font-size: var(--emu-semantic-font-sizes-narrow-medium);
  }
}

#home .resonic-grid__text ul {
  padding-left: 25px;
}

@media (min-width: 1024px) {
  #home .resonic-grid__text ul {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
    line-height: var(--emu-semantic-line-heights-wide-xxl);
  }
}

#home .resonic-grid__text ul > li:last-child {
  margin-bottom: var(--emu-common-spacing-none);
}

#home .resonic-grid__text ul li {
  margin-bottom: 17px;
}

#home .resonic-grid__text .emu-spacing-m__none p {
  margin-top: var(--emu-common-spacing-none);
}

#home .resonic-grid__text .cmp-image {
  max-width: 400px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
}

#home .resonic-grid__text .cmp-image img {
  width: 100%;
  height: auto;
}

@media (min-width: 1024px) {
  #home .resonic-grid__text .cmp-image {
    max-width: none;
    margin-bottom: var(--emu-common-spacing-none);
  }
}

#home .resonic-grid__wrapper {
  grid-gap: 40px;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 40px;
  display: flex;
}

@media (min-width: 1024px) {
  #home .resonic-grid__wrapper {
    margin-top: 40px;
  }
}

#home .resonic-grid__wrapper .resonic-grid__item.resonic-grid__item--testimonials img {
  width: 800px;
  height: 200px;
  -o-object-fit: cover;
  object-fit: cover;
  max-width: 100%;
  margin: auto;
}

@media (min-width: 1024px) {
  #home .resonic-grid__wrapper > div:nth-child(2n) .resonic-grid__item {
    flex-direction: row;
  }
}

#home .resonic-grid__item {
  grid-gap: 40px;
  flex-direction: column;
  gap: 40px;
  display: flex;
}

@media (min-width: 1024px) {
  #home .resonic-grid__item {
    flex-direction: row;
    align-items: center;
  }
}

#home .resonic-grid__item .patient-testimonial {
  padding-left: var(--emu-common-spacing-small);
  padding-right: var(--emu-common-spacing-small);
  margin-top: 40px;
}

#home .resonic-grid__item--before-after > div:last-child {
  display: none;
}

@media (min-width: 1024px) {
  #home .resonic-grid__item--before-after > div:last-child {
    display: block;
  }
}

#home .resonic-grid__item--hour-session .resonic-grid__item-asset-container .emu-comparison-slider {
  justify-content: flex-end;
}

#home .resonic-grid__item--reverse {
  flex-direction: column-reverse;
}

@media (min-width: 1024px) {
  #home .resonic-grid__item--reverse {
    flex-direction: row-reverse;
  }
}

#home .resonic-grid__item > div {
  flex: 1;
}

@media (min-width: 1024px) {
  #home .resonic-grid__item > div {
    width: calc(50% - 20px);
  }
}

#home .resonic-grid__slider-container sup {
  font-family: sans-serif;
  top: -6px;
}

#home .resonic-grid__slider-container p {
  padding-left: var(--emu-common-spacing-small);
  padding-right: var(--emu-common-spacing-small);
  font-size: 11.5px;
  line-height: 22px;
}

#home .resonic-grid__slider-container p br {
  display: none;
}

@media (min-width: 1024px) {
  #home .resonic-grid__slider-container p br {
    display: block;
  }

  #home .resonic-grid__slider-container p {
    padding: var(--emu-common-spacing-none);
    line-height: var(--emu-semantic-line-heights-narrow-large);
  }
}

#home .resonic-card__wrapper {
  grid-gap: 20px;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 40px;
  display: flex;
}

@media (min-width: 1024px) {
  #home .resonic-card__wrapper {
    grid-gap: 40px;
    flex-direction: row;
    gap: 40px;
    padding-top: 44px;
    padding-bottom: 44px;
  }

  #home .resonic-card__wrapper > div {
    width: calc(50% - 20px);
  }
}

#home .resonic-card__image img {
  width: 100%;
  height: auto;
  max-width: 400px;
}

.cq-Editable-dom--container .resonic-grid__item {
  flex-wrap: wrap;
}

.resonic-grid__item .emu-comparison-slider {
  display: flex;
}

@media (min-width: 1024px) {
  .cq-Editable-dom--container .resonic-grid__item > div {
    min-width: calc(50% - 20px);
  }
}

.resonic-grid__item img {
  height: 300px;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.cq-Editable-dom--container .resonic-card__wrapper {
  flex-wrap: wrap;
}

.cq-Editable-dom--container .resonic-card__wrapper > div {
  min-width: calc(50% - 20px);
}

#fap .bg--mix-purple {
  background: linear-gradient(110deg, #06004d 0%, var(--emu-semantic-colors-primary-purple-200) 6%, var(--emu-semantic-colors-primary-purple-300) 14%, var(--emu-semantic-colors-primary-purple-400) 24%, var(--emu-semantic-colors-primary-purple-500) 35%, var(--emu-semantic-colors-primary-purple-700) 48%, var(--emu-semantic-colors-primary-purple-800) 61%, var(--emu-semantic-colors-primary-purple-900) 72%, var(--emu-semantic-colors-primary-pink-100) 82%, var(--emu-semantic-colors-primary-pink-200) 90%, var(--emu-semantic-colors-primary-pink-400) 96%, var(--emu-semantic-colors-primary-pink-500) 100%);
}

#fap .fap-hero-section {
  padding-top: 48px;
  padding-right: var(--emu-common-spacing-small);
  padding-bottom: 40px;
  padding-left: var(--emu-common-spacing-small);
}

@media (min-width: 1024px) {
  #fap .fap-hero-section {
    padding-left: var(--emu-common-spacing-large);
    padding-right: var(--emu-common-spacing-large);
    padding-top: 150px;
    padding-bottom: 44px;
  }
}

#fap .fap-hero-section__title {
  margin-bottom: 20px;
}

@media (min-width: 1024px) {
  #fap .fap-hero-section__title {
    margin-bottom: 22px;
  }
}

#fap .fap-hero-section__title h1 {
  color: var(--emu-common-colors-white);
}

@media (min-width: 1024px) {
  #fap .fap-hero-section__title h1 {
    text-align: center;
    font-size: var(--emu-semantic-font-sizes-wide-xxxl);
    line-height: var(--emu-semantic-line-heights-wide-xxxl);
  }
}

#fap .fap-hero-section__subtitle {
  margin-bottom: 40px;
}

@media (min-width: 1024px) {
  #fap .fap-hero-section__subtitle {
    margin-bottom: 44px;
  }
}

#fap .fap-hero-section__subtitle p {
  color: var(--emu-common-colors-white);
  margin-bottom: 20px;
}

@media (min-width: 1024px) {
  #fap .fap-hero-section__subtitle p {
    text-align: center;
    font-size: var(--emu-semantic-font-sizes-wide-medium);
    line-height: var(--emu-semantic-line-heights-wide-large);
  }

  #fap .form-container {
    max-width: 1168px;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 44px;
    margin-bottom: 44px;
    display: flex;
  }

  #fap .form-container > .container {
    width: calc(50% - 50px);
  }
}

#hcp-home .hero-section {
  position: relative;
}

@media (min-width: 1024px) {
  #hcp-home .hero-section__teaser .teaser-container .aaaem-image {
    padding-top: 128px;
  }
}

#hcp-home .hero-section__teaser .teaser-container .aaaem-image img {
  width: 100%;
  max-width: 508px;
  margin-left: auto;
}

@media (min-width: 1440px) {
  #hcp-home .hero-section__teaser .teaser-container .content-container {
    padding-left: var(--emu-common-spacing-none);
    width: 100%;
  }
}

#hcp-home .hero-section__teaser .teaser-container .content {
  width: 60%;
  margin-left: var(--emu-common-spacing-none);
}

@media (min-width: 1024px) {
  #hcp-home .hero-section__teaser .teaser-container .content {
    width: 100%;
    margin-left: auto;
    padding-top: 114px;
  }
}

@media (min-width: 1440px) {
  #hcp-home .hero-section__teaser .teaser-container .content {
    padding-left: var(--emu-common-spacing-medium);
    padding-right: var(--emu-common-spacing-medium);
    max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
  }
}

@media (min-width: 1024px) {
  #hcp-home .hero-section__teaser .teaser-container .content {
    max-width: 80%;
    margin-left: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1560px) {
  #hcp-home .hero-section__teaser .teaser-container .content {
    max-width: 1300px;
    margin-left: auto;
  }
}

#hcp-home .hero-section__teaser .teaser-container .cmp-teaser__description {
  display: none;
}

@media (min-width: 1024px) {
  #hcp-home .hero-section__teaser .teaser-container .cmp-teaser__description {
    max-width: 1200px;
    display: block;
  }
}

#hcp-home .hero-section__teaser .teaser-container .title-lockup h3 {
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  line-height: var(--emu-semantic-line-heights-narrow-xl);
}

@media (min-width: 1024px) {
  #hcp-home .hero-section__teaser .teaser-container .title-lockup h3 {
    width: 100%;
    font-size: var(--emu-semantic-font-sizes-wide-xxxl);
    line-height: var(--emu-semantic-line-heights-wide-xxxl);
    padding-bottom: 44px;
  }
}

#hcp-home .hero-section__teaser .teaser-container .title-lockup h3 br {
  display: none;
}

@media (min-width: 1024px) {
  #hcp-home .hero-section__teaser .teaser-container .title-lockup h3 br {
    display: block;
  }
}

#hcp-home .hero-section__description {
  padding-top: 20px;
  padding-left: var(--emu-common-spacing-small);
  padding-right: var(--emu-common-spacing-small);
}

@media (min-width: 1024px) {
  #hcp-home .form-container {
    max-width: 1168px;
  }

  #hcp-home .form-container .form-content {
    flex-flow: wrap;
    justify-content: space-between;
  }

  #hcp-home .form-container .form-content > .options, #hcp-home .form-container .form-content > .text, #hcp-home .form-container .form-content > .recaptcha {
    width: calc(50% - 20px);
  }
}

#hcp-home .form-container .form-content .cmp-ajax-form-error, #hcp-home .form-container .form-content > .container {
  width: 100%;
}

#hcp-home .form-container__btn-wrapper {
  flex-direction: column-reverse;
}

@media (min-width: 1024px) {
  #hcp-home .form-container__btn-wrapper {
    flex-direction: column;
    margin-top: 24px;
  }

  #hcp-home .form-container__bottom-text {
    margin-left: -16px;
    margin-right: -16px;
  }
}

#error-page .error__text a {
  color: var(--emu-common-colors-white);
}

#isi-page .experiencefragment .resonic-isi__wrapper {
  display: none;
}

@media (min-width: 1024px) {
  #isi-page .banner-title {
    margin-top: 100px;
  }
}

#isi-page .resonic-isi__wrapper {
  padding-top: var(--emu-common-spacing-xl);
}

#isi-page .resonic-isi__wrapper p, #isi-page .resonic-isi__wrapper ul, #isi-page .resonic-isi__wrapper li {
  line-height: 1.5;
}

@media (min-width: 1024px) {
  #isi-page .isi-grid {
    grid-gap: 40px;
    flex-flow: wrap;
    gap: 40px;
    display: flex;
  }

  #isi-page .isi-grid > div {
    flex-basis: calc(50% - 20px);
  }
}

.aaaem-back-to-top-button {
  border-width: var(--emu-common-border-width-thin);
  border-style: solid;
  border-color: var(--emu-common-colors-white);
  padding: var(--emu-common-spacing-small);
  background: #472e7f66;
}

@media (min-width: 1440px) {
  .aaaem-back-to-top-button {
    right: calc(50% - 704px);
  }
}

.aaaem-button.aaaem-button__primary-filled {
  background: linear-gradient(#bc39cc 0%, #764cd4 139.09%), linear-gradient(0deg, #962ea3, #962ea3);
}

.aaaem-card {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  .aaaem-card {
    max-width: none;
  }
}

.aaaem-card__title-wrapper {
  display: none;
}

.aaaem-card__content-wrapper, .aaaem-card__body {
  padding-top: 15px;
}

@media (min-width: 1200px) {
  .aaaem-carousel {
    margin-left: auto;
    margin-right: auto;
  }
}

.aaaem-carousel .tns-slider {
  transition-timing-function: linear;
}

.emu-comparison-slider {
  max-width: 600px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

@media (min-width: 1024px) {
  .emu-comparison-slider {
    justify-content: unset;
  }
}

.emu-comparison-slider img-comparison-slider.rendered {
  width: 100%;
}

.emu-comparison-slider__handle {
  width: 40px;
  border-radius: var(--emu-common-sizing-medium);
  margin-left: -1px;
}

@media (min-width: 1024px) {
  .emu-comparison-slider__handle {
    width: var(--emu-common-sizing-large);
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: var(--emu-common-spacing-small);
    padding-right: var(--emu-common-spacing-small);
  }
}

.emu-comparison-slider img {
  width: 100%;
  height: auto;
}

.emu-comparison-slider__arrow {
  width: var(--emu-common-sizing-none);
  height: var(--emu-common-sizing-none);
  border-top: 5px solid var(--emu-common-colors-transparent);
  border-bottom: 5px solid var(--emu-common-colors-transparent);
  margin-top: var(--emu-common-spacing-xs);
  margin-top: var(--emu-common-spacing-none);
  transform: none;
}

@media (min-width: 1024px) {
  .emu-comparison-slider__arrow {
    border-top-width: 8px;
    border-bottom-width: 8px;
  }
}

.emu-comparison-slider__arrow--left {
  border-right: 8px solid var(--emu-common-colors-white);
  border-left: none;
}

@media (min-width: 1024px) {
  .emu-comparison-slider__arrow--left {
    border-right-width: 14px;
  }
}

.emu-comparison-slider__arrow--right {
  border-left: 8px solid var(--emu-common-colors-white);
  border-right: none;
}

@media (min-width: 1024px) {
  .emu-comparison-slider__arrow--right {
    border-left-width: 14px;
  }
}

:root {
  --isiBannerHeight: 0;
}

footer.footer {
  padding-top: 20px;
  padding-right: var(--emu-common-spacing-small);
  padding-bottom: 20px;
  padding-left: var(--emu-common-spacing-small);
}

@media (min-width: 1024px) {
  footer.footer {
    padding-top: 22px;
    padding-right: var(--emu-common-spacing-medium);
    padding-bottom: 22px;
    padding-left: var(--emu-common-spacing-medium);
  }
}

footer.footer p {
  margin: var(--emu-common-spacing-none);
}

footer.footer .resonic-footer__top-section {
  max-width: var(--emu-semantic-sizing-breakpoints-x-large);
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  footer.footer .resonic-footer__top-section {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 44px;
    padding-bottom: 20px;
    display: flex;
  }
}

footer.footer .resonic-footer__image-section {
  max-width: 232px;
  width: 100%;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  footer.footer .resonic-footer__image-section {
    margin: var(--emu-common-spacing-none);
  }
}

footer.footer .resonic-footer__logo img {
  height: 60px;
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  margin-bottom: 41px;
}

@media (min-width: 1024px) {
  footer.footer .resonic-footer__logo img {
    height: 66px;
    margin-bottom: 45px;
  }
}

footer.footer .resonic-footer__social-images-wrapper {
  grid-gap: 40px;
  gap: 40px;
  display: flex;
}

footer.footer .resonic-footer__links {
  text-align: center;
}

@media (min-width: 1024px) {
  footer.footer .resonic-footer__links {
    text-align: left;
  }
}

footer.footer .resonic-footer__links ul {
  margin: var(--emu-common-spacing-none);
  padding: var(--emu-common-spacing-none);
  list-style: none;
}

footer.footer .resonic-footer__links ul li {
  line-height: 20px;
  font-weight: var(--emu-common-font-weight-bold);
  color: var(--emu-semantic-colors-primary-purple-1100);
  margin-bottom: 20px;
}

@media (min-width: 1024px) {
  footer.footer .resonic-footer__links ul li {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
    line-height: var(--emu-semantic-line-heights-wide-large);
    margin-bottom: 22px;
  }
}

footer.footer .resonic-footer__links ul li:last-child {
  margin-bottom: var(--emu-common-spacing-none);
}

footer.footer .resonic-footer__links a {
  color: inherit;
  text-decoration: none;
}

footer.footer .resonic-footer__links-section {
  grid-gap: 36px;
  flex-direction: column;
  gap: 36px;
  display: flex;
}

@media (min-width: 1024px) {
  footer.footer .resonic-footer__links-section {
    grid-gap: 40px;
    flex-direction: row;
    gap: 40px;
  }
}

footer.footer .resonic-footer__external-link ul li a {
  align-items: center;
  display: inline-flex;
}

footer.footer .resonic-footer__external-link ul li img {
  width: var(--emu-common-sizing-small);
  height: auto;
}

footer.footer .resonic-footer__external-link ul li:last-child a {
  grid-gap: var(--emu-common-spacing-small);
  gap: var(--emu-common-spacing-small);
}

footer.footer .resonic-footer__external-link ul li:last-child img {
  max-width: 40px;
  width: auto;
  height: auto;
}

footer.footer .resonic-footer__allergan-logo {
  width: var(--emu-common-sizing-xxl);
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  footer.footer .resonic-footer__allergan-logo {
    width: 182px;
    margin: var(--emu-common-spacing-none);
  }
}

footer.footer .resonic-footer__allergan-logo img {
  width: 100%;
}

footer.footer .resonic-footer__bottom-section {
  max-width: 1408px;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

@media (min-width: 1024px) {
  footer.footer .resonic-footer__bottom-section {
    grid-gap: 40px;
    flex-direction: row;
    gap: 40px;
  }
}

footer.footer .resonic-footer__bottom-text {
  text-align: center;
}

@media (min-width: 1024px) {
  footer.footer .resonic-footer__bottom-text {
    text-align: unset;
    font-size: var(--emu-semantic-font-sizes-wide-medium);
    line-height: var(--emu-semantic-line-heights-wide-large);
  }

  footer.footer .resonic-footer__bottom-text br {
    display: none;
  }
}

.ajaxContainer .cmp-ajax-form-error {
  border: var(--emu-common-border-width-thin) solid var(--emu-common-colors-red-500);
  color: var(--emu-common-colors-red-500);
  margin-bottom: 30px;
  margin-top: var(--emu-common-spacing-none);
  padding: 10px;
  display: none;
}

.ajaxContainer .error-message {
  margin-top: var(--emu-common-spacing-xxs);
  margin-bottom: var(--emu-common-spacing-none);
  color: var(--emu-common-colors-red-500);
  font-size: var(--emu-common-font-sizes-narrow-large);
}

.ajaxContainer .cmp-ajax-form-success {
  display: none;
}

.ajaxContainer .cmp-form-text {
  position: relative;
}

.ajaxContainer .form-content {
  grid-row-gap: 20px;
  flex-direction: column;
  row-gap: 20px;
  display: flex;
}

@media (min-width: 1024px) {
  .ajaxContainer .form-content {
    grid-gap: 22px;
    gap: 22px;
  }
}

.ajaxContainer .form-content:before, .ajaxContainer .form-content:after {
  display: none;
}

.ajaxContainer .form-control {
  height: 60px;
  width: 100%;
  background-color: var(--emu-common-colors-white);
  border-radius: var(--emu-semantic-border-radius-xs);
  border: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-secondary-dark-gray-400);
  padding: 10px 9px;
}

.ajaxContainer label {
  color: var(--emu-semantic-colors-secondary-dark-gray-200);
  font-size: var(--emu-common-font-sizes-narrow-medium);
  font-weight: var(--emu-common-font-weight-bold);
  line-height: var(--emu-semantic-line-heights-narrow-large);
  position: absolute;
  top: 0;
  left: 9px;
}

@media (min-width: 1024px) {
  .ajaxContainer label {
    font-size: 11.48px;
  }
}

.ajaxContainer label.cmp-form-options__label {
  display: none;
}

@media (min-width: 1024px) {
  .ajaxContainer label {
    line-height: var(--emu-semantic-line-heights-wide-large);
    left: 10px;
  }
}

.ajaxContainer select.form-control {
  appearance: none;
  color: var(--emu-semantic-colors-secondary-dark-gray-300);
  cursor: pointer;
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  line-height: var(--emu-semantic-line-heights-narrow-large);
  background-image: url("resources/images/caret-down.png");
  background-position: 97%;
  background-repeat: no-repeat;
  background-size: 13px;
}

@media (min-width: 1024px) {
  .ajaxContainer select.form-control {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
    line-height: var(--emu-semantic-line-heights-wide-large);
  }
}

.ajaxContainer input.form-control {
  height: 66px;
  color: var(--emu-semantic-colors-secondary-dark-gray-300);
  padding-top: 30px;
}

@media (min-width: 1024px) {
  .ajaxContainer input.form-control {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
    line-height: var(--emu-semantic-line-heights-wide-large);
    padding: 33px 10px 11px;
  }
}

.ajaxContainer input.form-control::placeholder {
  color: var(--emu-semantic-colors-secondary-dark-gray-300);
}

.ajaxContainer .button {
  text-align: center;
}

.ajaxContainer .button button {
  padding-left: var(--emu-common-spacing-large);
  padding-right: var(--emu-common-spacing-large);
  padding-top: var(--emu-common-spacing-medium);
  padding-bottom: var(--emu-common-spacing-medium);
  border-radius: var(--emu-common-border-radius-small);
  border: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-primary-pink-300);
  background: linear-gradient(180deg, var(--emu-semantic-colors-primary-pink-600) 0%, var(--emu-semantic-colors-primary-purple-1200) 100%);
  color: var(--emu-common-colors-white);
  line-height: var(--emu-semantic-line-heights-narrow-large);
  font-weight: var(--emu-common-font-weight-bold);
}

.ajaxContainer.js-response-errors .cmp-ajax-form-error, .ajaxContainer.js-response-success .cmp-ajax-form-success {
  display: block;
}

.ajaxContainer.js-response-success .cmp-ajax-form-error, .ajaxContainer.js-response-success form {
  display: none;
}

.emu-google-maps {
  margin-left: var(--emu-common-spacing-small);
  margin-right: var(--emu-common-spacing-small);
  padding-bottom: 62.5%;
}

@media (min-width: 1024px) {
  .emu-google-maps {
    margin-left: var(--emu-common-spacing-medium);
    margin-right: var(--emu-common-spacing-medium);
    padding-bottom: var(--emu-common-spacing-none);
    height: 918px;
  }
}

@media (min-width: 1440px) {
  .emu-google-maps {
    max-width: calc(var(--emu-semantic-sizing-breakpoints-xx-large)  - var(--emu-common-spacing-large));
    margin-left: auto;
    margin-right: auto;
  }
}

.gm-style-iw p {
  margin-bottom: var(--emu-common-spacing-none);
  font-size: 10px;
  line-height: 15px;
}

@media (min-width: 1024px) {
  .gm-style-iw p {
    margin-bottom: var(--emu-common-spacing-medium);
    font-size: var(--emu-common-font-sizes-narrow-large);
    line-height: var(--emu-common-line-heights-narrow-large);
  }
}

.gm-style-iw a, .gm-style-iw b {
  color: var(--emu-semantic-colors-primary-purple-1100);
}

header.header {
  max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
  width: 100%;
  z-index: var(--emu-common-other-z-index-header);
  transition: all .3s ease-in-out;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media (min-width: 1024px) {
  header.header {
    padding-left: var(--emu-common-spacing-medium);
    padding-right: var(--emu-common-spacing-medium);
    top: 43px;
  }
}

header.header .cmp-container-header {
  width: 100%;
  background-color: var(--emu-common-colors-white);
  padding-top: 10px;
  padding-right: var(--emu-common-spacing-small);
  padding-bottom: 10px;
  padding-left: var(--emu-common-spacing-small);
  justify-content: space-between;
  display: flex;
  box-shadow: 0 2px 15px #1c254914;
}

@media (min-width: 1024px) {
  header.header .cmp-container-header {
    border-radius: var(--emu-common-border-radius-medium);
    justify-content: initial;
    padding-top: var(--emu-common-spacing-medium);
    padding-right: var(--emu-common-spacing-large);
    padding-bottom: var(--emu-common-spacing-medium);
    padding-left: var(--emu-common-spacing-large);
    align-items: center;
  }
}

header.header .aaaem-button, header.header .aaaem-button:hover, header.header .aaaem-button:active, header.header .aaaem-button:focus {
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
  padding-top: var(--emu-common-spacing-none);
  padding-bottom: var(--emu-common-spacing-none);
  background-color: var(--emu-common-colors-transparent);
  border: var(--emu-common-border-width-none);
  outline: var(--emu-common-outline-width-unset);
}

header.header .aaaem-button .cmp-button__text, header.header .aaaem-button:hover .cmp-button__text, header.header .aaaem-button:active .cmp-button__text, header.header .aaaem-button:focus .cmp-button__text {
  padding-right: var(--emu-common-spacing-xs);
}

header.header .header__global-navigation {
  display: none;
}

@media (min-width: 1024px) {
  header.header .header__content {
    width: 100%;
    align-items: center;
    display: flex;
  }
}

header.header .header__content > .container {
  width: 100%;
}

header.header .header__content > div:first-child {
  display: none;
}

@media (min-width: 1024px) {
  header.header .header__content > div:first-child {
    width: 97.5%;
    display: block;
    position: absolute;
    top: -28px;
    right: 20px;
  }
}

header.header .header__content > div:first-child .header__menu-eyebrow-navlink--view-patient {
  display: none;
}

header.header .header__content > div:first-child .header__menu-eyebrow-navlink ul {
  justify-content: flex-end;
  display: flex;
}

header.header .header__content > div:first-child .header__menu-eyebrow-navlink ul a {
  color: var(--emu-common-colors-white);
  font-weight: var(--emu-semantic-font-weight-500);
  font-size: var(--emu-common-font-sizes-narrow-medium);
  line-height: 22px;
  transition: all .3s;
}

@media (min-width: 1024px) {
  header.header .header__content > div:first-child .header__menu-eyebrow-navlink ul a {
    font-size: 15px;
    font-weight: var(--emu-common-font-weight-regular);
  }
}

header.header .header__content > div:first-child .header__menu-eyebrow-navlink ul a:hover {
  color: var(--emu-semantic-colors-primary-pink-600);
}

header.header .header__menu {
  height: calc(100vh - 94px);
  width: var(--emu-common-sizing-none);
  transition: all .3s ease-in-out;
  position: fixed;
  top: 94px;
  left: auto;
  right: -100%;
  overflow-y: auto;
}

@media (min-width: 1024px) {
  header.header .header__menu {
    width: 100%;
    height: auto;
    justify-content: space-between;
    align-items: center;
    display: flex;
    position: static;
    overflow: hidden;
  }

  header.header .header__menu > div:first-child {
    margin-left: auto;
    margin-right: auto;
  }
}

header.header .header__menu-fap {
  background-color: var(--emu-semantic-colors-secondary-light-gray-100);
  width: 100%;
  text-transform: uppercase;
  z-index: var(--emu-common-other-z-index-header);
  justify-content: flex-end;
  display: flex;
  position: fixed;
  top: 54px;
  left: 0;
}

header.header .header__menu-fap a {
  font-size: var(--emu-common-font-sizes-narrow-medium);
  line-height: var(--emu-semantic-line-heights-narrow-large);
  font-weight: var(--emu-common-font-weight-bold);
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: var(--emu-common-spacing-small);
  padding-right: var(--emu-common-spacing-small);
  white-space: pre;
  text-transform: uppercase;
  border: none;
  align-items: center;
  display: flex;
}

@media (min-width: 1024px) {
  header.header .header__menu-fap a {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
    line-height: var(--emu-semantic-line-heights-wide-large);
  }
}

header.header .header__menu-fap li {
  border: none;
}

header.header .header__menu-fap img {
  margin-right: var(--emu-common-spacing-small);
  margin-top: var(--emu-common-spacing-none);
  width: 9px;
  height: auto;
}

@media (min-width: 1024px) {
  header.header .header__menu-fap img {
    display: none;
  }

  header.header .header__menu-fap {
    padding: var(--emu-common-spacing-none);
    background-color: var(--emu-common-colors-transparent);
    text-transform: unset;
    display: block;
    position: static;
  }

  header.header .header__menu-fap a {
    font-weight: var(--emu-common-font-weight-bold);
  }
}

header.header .header__menu.js-toggle-on {
  width: 100%;
  background-color: var(--emu-common-colors-white);
  right: 0;
}

header.header .header__menu-wrapper {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (min-width: 1024px) {
  header.header .header__menu-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
}

header.header .header__menu-trigger.aaaem-button {
  margin-bottom: 6px;
  padding-top: 10px;
  padding-bottom: 9px;
  position: relative;
}

@media (min-width: 1024px) {
  header.header .header__menu-trigger.aaaem-button {
    display: none;
  }
}

header.header .header__menu-trigger.aaaem-button:hover, header.header .header__menu-trigger.aaaem-button:active, header.header .header__menu-trigger.aaaem-button:focus {
  padding-top: 10px;
  padding-bottom: 9px;
}

header.header .header__menu-trigger.aaaem-button > span.chevron, header.header .header__menu-trigger.aaaem-button > span, header.header .header__menu-trigger.aaaem-button:before, header.header .header__menu-trigger.aaaem-button:after {
  width: 23px;
  height: 2px;
  background-color: var(--emu-semantic-colors-primary-purple-1200);
  border-radius: var(--emu-common-border-radius-xxs);
  transition: all .25s ease-in-out;
}

header.header .header__menu-trigger.aaaem-button > span.chevron {
  display: block;
  position: absolute;
  transform: rotate(0);
}

header.header .header__menu-trigger.aaaem-button > span.chevron .chevron-down {
  display: none;
}

header.header .header__menu-trigger.aaaem-button:before, header.header .header__menu-trigger.aaaem-button:after {
  content: "";
  position: absolute;
  left: 0;
}

header.header .header__menu-trigger.aaaem-button:before {
  top: 1.5px;
}

header.header .header__menu-trigger.aaaem-button:after {
  bottom: .5px;
}

header.header .header__menu-trigger.aaaem-button.js-toggle-on:after {
  width: var(--emu-common-sizing-none);
  left: 50%;
}

header.header .header__menu-trigger.aaaem-button.js-toggle-on:before {
  width: var(--emu-common-sizing-none);
  top: 18px;
  left: 50%;
}

header.header .header__menu-trigger.aaaem-button.js-toggle-on > span.chevron {
  transform: rotate(-45deg);
}

header.header .header__menu-trigger.aaaem-button.js-toggle-on > span {
  transform: rotate(45deg);
}

header.header .header__main-logo {
  width: 120px;
  height: 34px;
}

.overflow-hidden {
  overflow: hidden;
}

@media (min-width: 1024px) {
  .overflow-hidden {
    overflow: auto;
  }
}

.menu-open {
  overflow: hidden;
}

@media (min-width: 1024px) {
  .menu-open {
    overflow-y: auto;
  }
}

.has-sticky-header header.header {
  top: -300px;
}

@media (min-width: 1024px) {
  .has-sticky-header header.header {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.has-sticky-header.menu-open header.header, .has-sticky-header.show-sticky-header header.header {
  top: 0;
}

.has-sticky-header.show-sticky-header header.header .header__menu-fap {
  box-shadow: 0 2px 4px var(--emu-semantic-colors-secondary-dark-gray-400);
}

@media (min-width: 1024px) {
  .has-sticky-header.show-sticky-header header.header .header__menu-fap {
    box-shadow: none;
  }
}

.has-sticky-header.show-sticky-header.menu-open header.header .header__menu-fap {
  box-shadow: 0 2px 4px var(--emu-common-colors-transparent);
}

@media (min-width: 1024px) {
  .skip-menu-container {
    display: none;
  }
}

#hcp-home .header__content .header__menu-eyebrow-navlink--view-patient {
  display: block;
}

#hcp-home .header__content .header__menu-eyebrow-navlink--healthcare-professional {
  display: none;
}

.cmp-experiencefragment--header {
  margin-top: 94px;
}

@media (min-width: 1024px) {
  .cmp-experiencefragment--header {
    margin-top: var(--emu-common-spacing-none);
  }
}

.emu-navigation__content-wrapper {
  position: relative;
}

.emu-navigation__content-wrapper nav ul {
  flex-direction: column;
}

@media (min-width: 1024px) {
  .emu-navigation__content-wrapper nav ul {
    flex-direction: row;
  }
}

.emu-navigation ul li {
  border-bottom: var(--emu-common-border-width-thin) solid var(--emu-common-colors-black);
}

@media (min-width: 1024px) {
  .emu-navigation ul li {
    border-bottom-width: var(--emu-common-border-width-none);
  }
}

.emu-navigation ul li a {
  font-weight: var(--emu-common-font-weight-regular);
  justify-content: space-between;
  font-size: 20px;
  line-height: 24px;
  display: flex;
}

.emu-navigation ul li a img {
  width: var(--emu-common-sizing-small);
  height: auto;
}

@media (min-width: 1024px) {
  .emu-navigation ul li a {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    padding-top: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-none);
    font-size: var(--emu-common-font-sizes-wide-medium);
    white-space: pre;
    line-height: 20px;
    font-size: var(--emu-semantic-font-sizes-wide-medium);
    line-height: var(--emu-semantic-line-heights-wide-large);
    margin-left: 20px;
  }
}

@media (min-width: 1200px) {
  .emu-navigation ul li a {
    margin-left: 40px;
  }
}

.emu-navigation ul li a img {
  width: var(--emu-common-sizing-small);
  height: var(--emu-common-sizing-small);
  margin-right: var(--emu-common-spacing-xxs);
  margin-top: 3px;
}

.emu-navigation ul > li span.nav-item {
  width: 100%;
  padding: var(--emu-common-spacing-none);
}

@media (min-width: 1024px) {
  .emu-navigation ul > li span.nav-item {
    display: none;
  }
}

.emu-navigation ul > li span.nav-item a {
  color: inherit;
}

.resonic-isi__wrapper {
  padding-top: 20px;
  padding-right: var(--emu-common-spacing-small);
  padding-bottom: 40px;
  padding-left: var(--emu-common-spacing-small);
  background-color: var(--emu-semantic-colors-secondary-light-gray-100);
}

@media (min-width: 1024px) {
  .resonic-isi__wrapper {
    padding-top: 22px;
    padding-right: var(--emu-common-spacing-medium);
    padding-bottom: 44px;
    padding-left: var(--emu-common-spacing-medium);
  }
}

.resonic-isi__wrapper [data-component="text"] h3, .resonic-isi__wrapper [data-component="text"] h4 {
  color: var(--emu-semantic-colors-primary-purple-1100);
  font-size: var(--emu-semantic-font-sizes-narrow-large);
  margin-top: 35px;
  margin-bottom: 15px;
}

.resonic-isi__wrapper [data-component="text"] h2 {
  font-size: 25.92px;
  line-height: var(--emu-semantic-line-heights-narrow-medium);
  color: var(--emu-semantic-colors-primary-purple-1100);
  margin-bottom: 20px;
}

@media (min-width: 1024px) {
  .resonic-isi__wrapper [data-component="text"] h2 {
    font-size: 34.85px;
    line-height: var(--emu-semantic-line-heights-wide-xl);
    margin-bottom: 22px;
  }
}

.resonic-isi__wrapper [data-component="text"] p, .resonic-isi__wrapper [data-component="text"] ul, .resonic-isi__wrapper [data-component="text"] li {
  margin: var(--emu-common-spacing-none);
  color: var(--emu-semantic-colors-secondary-dark-gray-300);
  margin-bottom: 20px;
  font-size: 15px;
}

@media (min-width: 1024px) {
  .resonic-isi__wrapper [data-component="text"] p, .resonic-isi__wrapper [data-component="text"] ul, .resonic-isi__wrapper [data-component="text"] li {
    line-height: var(--emu-semantic-line-heights-narrow-large);
    font-size: var(--emu-semantic-font-sizes-narrow-medium);
    margin-bottom: 22px;
  }
}

.resonic-isi__wrapper [data-component="text"] p:last-child {
  margin-bottom: var(--emu-common-sizing-none);
}

.resonic-isi__wrapper [data-component="text"] ul {
  padding-left: var(--emu-common-spacing-none);
  list-style: none;
}

.resonic-isi__wrapper [data-component="text"] li {
  margin-bottom: 10px;
  padding-left: 28px;
  position: relative;
}

.resonic-isi__wrapper [data-component="text"] li:before {
  content: "";
  width: 4px;
  height: 4px;
  background-color: var(--emu-common-colors-black);
  border-radius: 50%;
  position: absolute;
  top: 6px;
  left: 6px;
}

.resonic-isi__wrapper [data-component="text"] a {
  color: inherit;
}

.teaser .content-container {
  top: var(--emu-common-spacing-none);
  position: absolute;
}

@media (min-width: 1024px) {
  .teaser .content-container {
    padding-left: var(--emu-common-spacing-medium);
  }
}

.teaser .content-container .title-lockup {
  max-width: 100%;
}

.teaser .content-container .title-lockup h3 {
  font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
  line-height: var(--emu-semantic-line-heights-narrow-xxxl);
  padding-bottom: 20px;
}

@media (min-width: 1024px) {
  .teaser .content-container .title-lockup h3 {
    font-size: var(--emu-semantic-font-sizes-wide-xxxl);
    line-height: var(--emu-semantic-line-heights-wide-xxxl);
    padding-bottom: 22px;
  }
}

.teaser .content-container .title-lockup h3 sup {
  font-size: 65%;
  line-height: var(--emu-common-spacing-none);
  position: relative;
  top: -1px;
}

.teaser .content-container .cmp-teaser__description p {
  margin: var(--emu-common-spacing-none);
}

@media (min-width: 1024px) {
  .teaser .content-container .cmp-teaser__description p {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
    line-height: var(--emu-semantic-line-heights-wide-large);
  }
}

[data-component="text"], [data-component="title"] {
  color: var(--emu-common-colors-black);
  font: var(--emu-semantic-typography-narrow-body-regular);
}

[data-component="text"] p, [data-component="text"] li, [data-component="title"] p, [data-component="title"] li {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  font-family: var(--emu-semantic-font-families-body);
  margin: var(--emu-common-spacing-none);
}

[data-component="text"] sup, [data-component="title"] sup {
  vertical-align: baseline;
  font-size: 65%;
  line-height: 0;
  position: relative;
  top: -1px;
}

[data-component="text"] b, [data-component="title"] b {
  font-weight: var(--emu-common-font-weight-bold);
}

/*# sourceMappingURL=main.css.map */
