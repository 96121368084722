#isi-page {
  .experiencefragment {
    .resonic-isi__wrapper {
      display: none;
    }
  }

  .banner-title {
    @include mq('large') {
      margin-top: 100px;
    }
  }

  .resonic-isi__wrapper {
    padding-top: var(--emu-common-spacing-xl);

    p,
    ul,
    li {
      line-height: 1.5;
    }
  }

  .isi-grid {
    @include mq('large') {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 40px;
    }

    > div {
      @include mq('large') {
        flex-basis: calc(50% - 20px);
      }
    }
  }
}
