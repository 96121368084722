footer.footer {
  padding-top: 20px;
  padding-right: var(--emu-common-spacing-small);
  padding-bottom: 20px;
  padding-left: var(--emu-common-spacing-small);

  @include mq('large') {
    padding-top: 22px;
    padding-right: var(--emu-common-spacing-medium);
    padding-bottom: 22px;
    padding-left: var(--emu-common-spacing-medium);
  }

  p {
    margin: var(--emu-common-spacing-none);
  }

  .resonic-footer {
    &__top-section {
      margin-bottom: 40px;
      max-width: var(--emu-semantic-sizing-breakpoints-x-large);
      margin-left: auto;
      margin-right: auto;

      @include mq('large') {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 44px;
        padding-bottom: 20px;
      }
    }

    &__image-section {
      max-width: 232px; // As per design.
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 40px;

      @include mq('large') {
        margin: var(--emu-common-spacing-none);
      }
    }

    &__logo img {
      height: 60px; // As per design.
      margin-bottom: 41px;
      width: 100%;
      object-fit: contain;

      @include mq('large') {
        height: 66px; // As per design.
        margin-bottom: 45px;
      }
    }

    &__social-images-wrapper {
      display: flex;
      gap: 40px;
    }

    &__links {
      text-align: center; // As per live site.

      @include mq('large') {
        text-align: left;
      }

      ul {
        margin: var(--emu-common-spacing-none);
        padding: var(--emu-common-spacing-none);
        list-style: none;

        li {
          line-height: 20px;
          font-weight: var(--emu-common-font-weight-bold);
          color: var(--emu-semantic-colors-primary-purple-1100);
          margin-bottom: 20px;

          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-wide-medium);
            line-height: var(--emu-semantic-line-heights-wide-large);
            margin-bottom: 22px;
          }

          &:last-child {
            margin-bottom: var(--emu-common-spacing-none);
          }
        }
      }

      a {
        text-decoration: none;
        color: inherit;
      }

      &-section {
        display: flex;
        flex-direction: column;
        gap: 36px;

        @include mq('large') {
          flex-direction: row;
          gap: 40px;
        }
      }
    }

    &__external-link {
      ul li {
        a {
          display: inline-flex;
          align-items: center;
        }

        img {
          width: var(--emu-common-sizing-small);
          height: auto;
        }

        &:last-child {
          a {
            gap: var(--emu-common-spacing-small);
          }

          img {
            max-width: 40px;
            width: auto;
            height: auto;
          }
        }
      }
    }

    &__allergan-logo {
      width: var(--emu-common-sizing-xxl); // As per figma design.
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;

      @include mq('large') {
        width: 182px; // As per figma design.
        margin: var(--emu-common-spacing-none);
      }

      img {
        width: 100%;
      }
    }

    &__bottom {
      &-section {
        display: flex;
        flex-direction: column;
        max-width: 1408px; // As per figma design.
        margin-left: auto;
        margin-right: auto;

        @include mq('large') {
          flex-direction: row;
          gap: 40px;
        }
      }

      &-text {
        text-align: center; // As per live site.

        @include mq('large') {
          text-align: unset;
          font-size: var(--emu-semantic-font-sizes-wide-medium);
          line-height: var(--emu-semantic-line-heights-wide-large);
        }

        br {
          @include mq('large') {
            display: none;
          }
        }
      }
    }
  }
}
