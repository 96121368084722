@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/gilroy/gilroy_black/gilroy-black.woff2')
      format('woff'),
    url('./assets/fonts/gilroy/gilroy_black/gilroy-black.woff') format('woff');
  font-display: swap;
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/gilroy/gilroy_blackitalic/gilroy-blackitalic.woff2')
      format('woff'),
    url('./assets/fonts/gilroy/gilroy_blackitalic/gilroy-blackitalic.woff')
      format('woff');
  font-display: swap;
  font-style: italic;
  font-weight: 900;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/gilroy/gilroy_bold/gilroy-bold.woff2') format('woff'),
    url('./assets/fonts/gilroy/gilroy_bold/gilroy-bold.woff') format('woff');
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/gilroy/gilroy_bolditalic/gilroy-bolditalic.woff2')
      format('woff'),
    url('./assets/fonts/gilroy/gilroy_bolditalic/gilroy-bolditalic.woff')
      format('woff');
  font-display: swap;
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/gilroy/gilroy_extrabold/gilroy-extrabold.woff2')
      format('woff'),
    url('./assets/fonts/gilroy/gilroy_extrabold/gilroy-extrabold.woff')
      format('woff');
  font-display: swap;
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/gilroy/gilroy_extrabolditalic/gilroy-extrabolditalic.woff2')
      format('woff'),
    url('./assets/fonts/gilroy/gilroy_extrabolditalic/gilroy-extrabolditalic.woff')
      format('woff');
  font-display: swap;
  font-style: italic;
  font-weight: 800;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/gilroy/gilroy_italic/gilroy-regularitalic.woff2')
      format('woff'),
    url('./assets/fonts/gilroy/gilroy_italic/gilroy-regularitalic.woff')
      format('woff');
  font-display: swap;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/gilroy/gilroy_light/gilroy-light.woff2')
      format('woff'),
    url('./assets/fonts/gilroy/gilroy_light/gilroy-light.woff') format('woff');
  font-display: swap;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/gilroy/gilroy_lightitalic/gilroy-lightitalic.woff2')
      format('woff'),
    url('./assets/fonts/gilroy/gilroy_lightitalic/gilroy-lightitalic.woff')
      format('woff');
  font-display: swap;
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/gilroy/gilroy_medium/gilroy-medium.woff2')
      format('woff'),
    url('./assets/fonts/gilroy/gilroy_medium/gilroy-medium.woff') format('woff');
  font-display: swap;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/gilroy/gilroy_mediumitalic/gilroy-mediumitalic.woff2')
      format('woff'),
    url('./assets/fonts/gilroy/gilroy_mediumitalic/gilroy-mediumitalic.woff')
      format('woff');
  font-display: swap;
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/gilroy/gilroy_regular/gilroy-regular.woff2')
      format('woff'),
    url('./assets/fonts/gilroy/gilroy_regular/gilroy-regular.woff')
      format('woff');
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}
