.resonic-isi__wrapper {
  padding-top: 20px;
  padding-right: var(--emu-common-spacing-small);
  padding-bottom: 40px;
  padding-left: var(--emu-common-spacing-small);
  background-color: var(--emu-semantic-colors-secondary-light-gray-100);

  @include mq('large') {
    padding-top: 22px;
    padding-right: var(--emu-common-spacing-medium);
    padding-bottom: 44px;
    padding-left: var(--emu-common-spacing-medium);
  }

  [data-component='text'] {
    h3,
    h4 {
      color: var(--emu-semantic-colors-primary-purple-1100);
      margin-bottom: 15px;
      margin-top: 35px;
      font-size: var(--emu-semantic-font-sizes-narrow-large);
    }

    h2 {
      font-size: 25.92px; // As per figma design.
      line-height: var(--emu-semantic-line-heights-narrow-medium);
      color: var(--emu-semantic-colors-primary-purple-1100);
      margin-bottom: 20px;

      @include mq('large') {
        font-size: 34.85px; // As per figma design.
        line-height: var(--emu-semantic-line-heights-wide-xl);
        margin-bottom: 22px;
      }
    }

    p,
    ul,
    li {
      margin: var(--emu-common-spacing-none);
      font-size: 15px;
      color: var(--emu-semantic-colors-secondary-dark-gray-300);
      margin-bottom: 20px;
      @include mq('large') {
        line-height: var(--emu-semantic-line-heights-narrow-large);
        font-size: var(--emu-semantic-font-sizes-narrow-medium);
        margin-bottom: 22px; //to match with figma
      }
    }
    p:last-child{
      margin-bottom: var(--emu-common-sizing-none);
    }
    

    ul {
      padding-left: var(--emu-common-spacing-none);
      list-style: none;
    }

    li {
      position: relative;
      padding-left: 28px;
      margin-bottom: 10px;

      &::before {
        content: '';
        position: absolute;
        left: 6px;
        top: 6px;
        width: 4px;  //to match with figma
        height: 4px;
        border-radius: 50%;
        background-color: var(--emu-common-colors-black);
      }
    }

    a {
      color: inherit;
    }
  }
}
