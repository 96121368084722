.teaser {
  .content-container {
    position: absolute;
    top: var(--emu-common-spacing-none);

    @include mq('large') {
      padding-left: var(--emu-common-spacing-medium);
    }

    .title-lockup {
      max-width: 100%; // to match live site

      h3 {
        padding-bottom: 20px;
        font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
        line-height: var(--emu-semantic-line-heights-narrow-xxxl);

        @include mq('large') {
          padding-bottom: 22px;
          font-size: var(--emu-semantic-font-sizes-wide-xxxl);
          line-height: var(--emu-semantic-line-heights-wide-xxxl);
        }

        sup {
          font-size: 65%;
          line-height: var(--emu-common-spacing-none);
          top: -1px;
          position: relative;
        }
      }
    }

    .cmp-teaser__description {
      p {
        margin: var(--emu-common-spacing-none);

        @include mq('large') {
          font-size: var(--emu-semantic-font-sizes-wide-medium);
          line-height: var(--emu-semantic-line-heights-wide-large);
        }
      }
    }
  }
}
