#home {
  .hero-section {
    &__img {
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  // Carousel
  .testimonials-container {
    padding-top: 20px; // to match figma design
    padding-bottom: 20px; // to match figma design

    @include mq('large') {
      padding-top: 44px; // to match figma design
      padding-bottom: 44px; // to match figma design
    }

    .patient-testimonial {
      padding-left: var(--emu-common-spacing-large);
      padding-right: var(--emu-common-spacing-large);
    }
  }

  // overview section
  .overview {
    display: flex;
    flex-direction: column;

    @include mq('x-large') {
      padding-left: var(--emu-common-spacing-small);
      padding-right: var(--emu-common-spacing-small);
    }

    &__container {
      padding-top: 40px;
      padding-right: var(--emu-common-spacing-small);
      padding-bottom: 40px;
      padding-left: var(--emu-common-spacing-small);
    }

    &__title h2 {
      font-weight: var(--emu-common-font-weight-bold);

      @include mq('large') {
        font-size: var(--emu-semantic-font-sizes-narrow-xl);
        line-height: 33px;
      }
    }

    &__subtitle h3 {
      @include mq('large') {
        font-size: var(--emu-semantic-font-sizes-wide-xl);
        line-height: var(--emu-semantic-line-heights-wide-xl);
      }
      sup {
        top: -9px; //to match sup of title with figma
        @include mq('large') {
          top: -15px;
        }
      }
    }

    &__text p {
      color: var(--emu-semantic-colors-secondary-dark-gray-300);
      margin-top: 20px; //according to figma

      @include mq('large') {
        font-size: var(--emu-semantic-font-sizes-wide-medium);
        line-height: var(--emu-semantic-line-heights-wide-xxl);
      }

      br {
        display: none;

        @include mq('large') {
          display: block;
        }
      }
    }

    &__benefit {
      background-color: var(--emu-semantic-colors-secondary-light-gray-100);
      padding-top: 20px; //according to figma
      padding-bottom: 20px;
      padding-left: var(--emu-common-sizing-small);
      padding-right: var(--emu-common-sizing-small);

      @include mq('large') {
        display: flex;
        max-width: 1040px; //As per figma
        flex-wrap: wrap;
        gap: 40px;
        justify-content: space-between;
        background: var(--emu-common-colors-transparent);
        padding-top: var(--emu-common-spacing-none);
        padding-bottom: 40px; //to match figma
        margin-left: auto;
        margin-right: auto;
      }

      &-inner-container {
        .cmp-text,
        .cmp-title,
        .cmp-image {
          text-align: center;

          @include mq('large') {
            text-align: left;
          }
        }
      }

      > div.container {
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: var(--emu-common-spacing-small);
        padding-right: var(--emu-common-spacing-small);

        @include mq('large') {
          width: calc(
            50% - 20px
          ); // 40px is the gap. each item contributes to 20px
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
          padding-top: var(--emu-common-spacing-none);
          padding-bottom: var(--emu-common-spacing-none);
        }
      }

      &-image {
        img {
          width: 48px;
          height: auto;
          display: inline-block;

          @include mq('large') {
            width: var(--emu-common-sizing-large);
            margin-bottom: var(--emu-common-spacing-small);
          }
        }
      }

      &-title {
        h4 {
          font-weight: var(--emu-common-font-weight-bold);
          line-height: var(--emu-semantic-line-heights-narrow-medium);
          margin-bottom: 20px;

          @include mq('large') {
            line-height: 33px;
            font-size: var(--emu-semantic-font-sizes-narrow-xl);
          }
        }
      }

      &-text {
        p {
          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-wide-medium);
            line-height: var(--emu-semantic-line-heights-wide-large);
            margin-bottom: var(--emu-common-spacing-small);
          }
        }
      }
    }

    &__disclaimer {
      padding-bottom: 20px;
      background-color: var(--emu-semantic-colors-secondary-light-gray-100);
      br {
        display: block;
        @include mq('large') {
          display: none;
        }
      }
      @include mq('large') {
        background-color: var(--emu-common-colors-transparent);
        width: fit-content;
        padding-bottom: 0;
      }

      p {
        font-size: 11.48px;
        line-height: 22px;
      }
    }
  }

  // Find a Provider Section
  .provider {
    background-color: var(--emu-semantic-colors-primary-purple-1000);

    @include mq('large') {
      display: flex;
      padding-top: 33px;
      padding-bottom: 33px;
      position: relative;
    }

    &__inner {
      padding-top: 44px; //according to figma
      padding-right: var(--emu-common-spacing-large);
      padding-bottom: 36px;
      padding-left: var(--emu-common-spacing-large);

      @include mq('large') {
        padding-top: var(--emu-common-spacing-large);
        padding-bottom: var(--emu-common-spacing-large);
      }
    }

    // separator
    > div:first-child {
      &::after {
        content: '';
        background-color: var(--emu-semantic-colors-primary-purple-1200);
        width: 92%; // to match figma
        height: var(--emu-common-border-width-thin);
        display: block;
        margin-left: auto;
        margin-right: auto;

        @include mq('large') {
          display: none;
        }
      }
    }

    > div.container {
      position: relative;

      @include mq('large') {
        flex: 1;
        align-self: center;
      }
    }

    &__text h3 {
      font-size: var(--emu-semantic-font-sizes-narrow-xxl);
      font-weight: var(--emu-common-font-weight-bold);

      @include mq('large') {
        font-size: var(--emu-semantic-font-sizes-wide-xxl);
        line-height: 33px; //According to figma
      }

      br {
        display: none;

        @include mq('large') {
          display: block;
        }
      }
    }

    &__subtext p {
      margin-top: 20px;

      @include mq('large') {
        margin-top: 22px;
        font-size: var(--emu-semantic-font-sizes-wide-medium);
        line-height: var(--emu-semantic-line-heights-wide-xxl);
      }
    }

    &__button {
      font-weight: var(--emu-common-font-weight-bold);
      line-height: var(--emu-semantic-line-heights-narrow-large);
      margin-top: 40px; //according to given figma

      @include mq('large') {
        margin-top: 44px;
      }
    }
  }

  // About section
  .about {
    margin-top: 40px;

    .resonic-grid__text {
      // mq needed to make it look good
      @include mq('1240px') {
        padding: var(--emu-common-spacing-none);
      }
    }

    &__content-wrapper {
      margin-bottom: 20px;

      @include mq('large') {
        display: flex;
        flex-wrap: nowrap;
        gap: 75px; // as per figma
        margin-bottom: 28px;
      }
      .image {
        @include mq('large') {
          display: none;
        }
      }
      img {
        margin-top: 60px; //to match figma
        margin-left: auto;
        margin-right: auto;
      }
    }

    &__title {
    }

    &__cards-wrapper {
      @include mq('large') {
        display: flex;
        flex-wrap: nowrap;
        gap: 75px; // as per figma
      }
    }

    &__card {
      display: flex;
      flex-direction: column;
      gap: var(--emu-common-spacing-large);
      margin-bottom: 26px;

      @include mq('large') {
        margin-bottom: 20px;
      }

      img {
        max-width: min(100%, 600px); //to match figma
        margin-left: auto;
        margin-right: auto;
      }
    }

    &__tradeoffs {
      margin-top: 24px;
    }
  }

  .resonic-grid {
    &__text {
      padding-left: var(--emu-common-spacing-small);
      padding-right: var(--emu-common-spacing-small);
      sup {
        top: -6px; //to match figma
        font-family: sans-serif; //to match figma
      }

      h2 {
        font-weight: var(--emu-common-font-weight-bold);
        color: var(--emu-semantic-colors-primary-pink-500);
        @include mq('large') {
          font-size: var(--emu-semantic-font-sizes-narrow-xl);
          line-height: 33px;
        }
      }

      h3 {
        color: var(--emu-semantic-colors-primary-purple-1100);

        @include mq('large') {
          font-size: var(--emu-semantic-font-sizes-wide-xl);
          line-height: var(--emu-semantic-line-heights-wide-xl);
        }
      }

      h4 {
        color: var(--emu-semantic-colors-primary-pink-300);
        font-size: var(--emu-semantic-font-sizes-narrow-medium);
        line-height: var(--emu-semantic-line-heights-narrow-large);
        font-weight: var(--emu-common-font-weight-bold);
        margin-top: var(--emu-common-spacing-large);
        @include mq('large') {
          font-size: var(--emu-semantic-font-sizes-wide-medium);
          line-height: var(--emu-semantic-line-heights-wide-large);
        }

        & + p {
          margin-top: var(--emu-common-spacing-none);
        }
      }

      p {
        margin-top: 20px;
        color: var(--emu-semantic-colors-secondary-dark-gray-300);
        line-height: var(--emu-semantic-line-heights-narrow-large);
      }
      @include mq('large') {
        p:first-of-type {
          font-size: var(--emu-semantic-font-sizes-wide-medium);
        }
        p:not(:first-of-type) {
          font-size: var(--emu-semantic-font-sizes-narrow-medium);
        }
      }

      ul {
        padding-left: 25px;
        @include mq('large') {
          font-size: var(--emu-semantic-font-sizes-wide-medium);
          line-height: var(--emu-semantic-line-heights-wide-xxl);
        }

        > li:last-child {
          margin-bottom: var(--emu-common-spacing-none);
        }

        li {
          margin-bottom: 17px;
        }
      }

      .emu-spacing-m__none {
        p {
          margin-top: var(--emu-common-spacing-none);
        }
      }

      .cmp-image {
        max-width: 400px; // as per figma
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 40px;

        img {
          width: 100%;
          height: auto;
        }

        @include mq('large') {
          max-width: none;
          margin-bottom: var(--emu-common-spacing-none);
        }
      }
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      gap: 40px;
      margin-bottom: 40px;
      @include mq('large') {
        margin-top: 40px; //to match figma
      }
      .resonic-grid__item.resonic-grid__item--testimonials {
        img {
          width: 800px;
          height: 200px;
          object-fit: cover;
          max-width: 100%;
          margin: auto;
        }
      }

      > div {
        @include mq('large') {
          &:nth-child(even) {
            .resonic-grid__item {
              flex-direction: row;
            }
          }
        }
      }
    }

    &__item {
      display: flex;
      flex-direction: column;
      gap: 40px;

      @include mq('large') {
        flex-direction: row;
        align-items: center;
      }

      .patient-testimonial {
        margin-top: 40px;
        padding-left: var(--emu-common-spacing-small);
        padding-right: var(--emu-common-spacing-small);
      }

      // tech -
      &--technology {
        // intentionally left empty to facilitate any additional styles in future
      }

      // testimonial
      &--testimonial {
        // intentionally left empty to facilitate any additional styles in future
      }

      // before after comparison slider
      &--before-after {
        .resonic-grid__item-asset-container {
          .emu-comparison-slider {
          }
        }

        > div:last-child {
          display: none;

          @include mq('large') {
            display: block;
          }
        }
      }

      // single hour session before after comparison slider
      &--hour-session {
        .resonic-grid__item-asset-container {
          .emu-comparison-slider {
            justify-content: flex-end;
          }
        }
      }

      &--reverse {
        flex-direction: column-reverse;
        @include mq('large') {
          flex-direction: row-reverse;
        }
      }

      > div {
        flex: 1;

        @include mq('large') {
          width: calc(50% - 20px);
        }
      }
    }
    &__slider-container {
      sup {
        top: -6px; //to match figma
        font-family: sans-serif;
      }
      p {
        font-size: 11.5px; //needed to match live
        line-height: 22px;
        padding-left: var(--emu-common-spacing-small);
        padding-right: var(--emu-common-spacing-small);
        br {
          display: none;
          @include mq('large') {
            display: block;
          }
        }
        @include mq('large') {
          padding: var(--emu-common-spacing-none);
          line-height: var(--emu-semantic-line-heights-narrow-large);
        }
      }
    }
  }

  // cards section
  .resonic-card {
    &__wrapper {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding-bottom: 40px;

      @include mq('large') {
        flex-direction: row;
        gap: 40px;
        padding-top: 44px;
        padding-bottom: 44px;
      }

      > div {
        @include mq('large') {
          width: calc(50% - 20px);
        }
      }
    }

    &__image {
      img {
        width: 100%;
        height: auto;
        max-width: 400px;
      }
    }
  }
}

// START: Author mode styles
.resonic-grid__item {
  @include aem-editor-view {
    flex-wrap: wrap;
  }

  .emu-comparison-slider {
    display: flex;
  }

  > div {
    @include aem-editor-view {
      @include mq('large') {
        min-width: calc(50% - 20px);
      }
    }
  }
  img {
    //to match the figma image
    height: 300px;
    width: 100%;
    object-fit: cover;
  }
}

.resonic-card__wrapper {
  @include aem-editor-view {
    flex-wrap: wrap;
  }

  > div {
    @include aem-editor-view {
      min-width: calc(50% - 20px);
    }
  }
}
