// set visibility by device/screen size
.desktop-view {
  display: none;

  @include mq('large') {
    display: block;
  }
}

// visible on tablet size & larger
.tablet-desktop-view {
  display: none;

  @include mq('large') {
    display: block;
  }
}

// visible on tablet size & smaller
.tablet-mobile-view {
  display: block;

  @include mq('large') {
    display: none;
  }
}

.mobile-view {
  display: block;

  @include mq('large') {
    display: none;
  }
}

// padding adjustments
.no-pad {
  padding: 0;
}

.no-x-pad {
  padding-left: 0;
  padding-right: 0;
}

.no-y-pad {
  padding-top: 0;
  padding-bottom: 0;
}

// content related util classes. Used to center buttons mostly.
.content--centered {
  text-align: center;
}

.container--large {
  max-width: var(--emu-semantic-sizing-breakpoints-x-large);
  margin-left: auto;
  margin-right: auto;
}

.container--xl {
  max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
  margin-left: auto;
  margin-right: auto;
}

.bg--primary-purple-1000 {
  background-color: var(--emu-semantic-colors-primary-purple-1000);
}

.bg--mix-purple {
  //to match the figma
  background: linear-gradient(
    170deg,
    #06004d 0%,
    var(--emu-semantic-colors-primary-purple-200) 6%,
    var(--emu-semantic-colors-primary-purple-300) 14%,
    var(--emu-semantic-colors-primary-purple-400) 24%,
    var(--emu-semantic-colors-primary-purple-500) 35%,
    var(--emu-semantic-colors-primary-purple-700) 48%,
    var(--emu-semantic-colors-primary-purple-800) 61%,
    var(--emu-semantic-colors-primary-purple-900) 72%,
    var(--emu-semantic-colors-primary-pink-100) 82%,
    var(--emu-semantic-colors-primary-pink-200) 90%,
    var(--emu-semantic-colors-primary-pink-400) 96%,
    var(--emu-semantic-colors-primary-pink-500) 100%
  );
} // as per live

// text related sizes and colors
[data-component='text'],
[data-component='title'] {
  &[class*='text--color-'] {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: inherit;
    }
  }

  &[class*='font-'] {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    li {
      font-family: inherit;
    }
  }

  &.text--color-white {
    color: var(--emu-common-colors-white);
  }

  &.text--color-black {
    color: var(--emu-common-colors-black);
  }

  &.text--color-pink {
    color: var(--emu-semantic-colors-primary-pink-500);
  }

  &.text--purple {
    color: var(--emu-semantic-colors-primary-purple-1100);
  }
}

//utility classes for text
[data-component='text'] {
  &.text-primary {
    @include mq('large') {
      font-size: var(--emu-semantic-font-sizes-wide-medium);
      line-height: var(--emu-semantic-line-heights-wide-large);
    }
  }

  &.text-secondary {
    font-size: var(--emu-common-font-sizes-narrow-medium);
    line-height: var(--emu-semantic-line-heights-narrow-large);
    @include mq('large') {
      font-size: 11px;
      line-height: var(--emu-semantic-line-heights-wide-large);
    }
  }
}

//utility classes for adding padding left and right to the container
.container--spacing {
  @include mq('large') {
    padding-left: var(--emu-common-spacing-medium);
    padding-right: var(--emu-common-spacing-medium);
  }
  @include mq('x-large') {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}
