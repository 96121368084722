header.header {
  position: fixed;
  max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
  transform: translateX(-50%);
  left: 50%;
  width: 100%;
  top: 0;
  z-index: var(--emu-common-other-z-index-header);
  transition: all 0.3s ease-in-out;

  @include mq('large') {
    top: 43px;
    padding-left: var(--emu-common-spacing-medium);
    padding-right: var(--emu-common-spacing-medium);
  }

  .cmp-container-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: var(--emu-common-colors-white);
    padding-top: 10px;
    padding-right: var(--emu-common-spacing-small);
    padding-bottom: 10px;
    padding-left: var(--emu-common-spacing-small);
    box-shadow: 0 2px 15px 0 rgba(28, 37, 73, 0.08); //as per live

    @include mq('large') {
      border-radius: var(--emu-common-border-radius-medium);
      justify-content: initial;
      align-items: center;
      padding-top: var(--emu-common-spacing-medium);
      padding-right: var(--emu-common-spacing-large);
      padding-bottom: var(--emu-common-spacing-medium);
      padding-left: var(--emu-common-spacing-large);
    }
  }

  //remove initial styles for button
  .aaaem-button,
  .aaaem-button:hover,
  .aaaem-button:active,
  .aaaem-button:focus {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    padding-top: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-none);
    background-color: var(--emu-common-colors-transparent);
    border: var(--emu-common-border-width-none);
    outline: var(--emu-common-outline-width-unset);
    .cmp-button__text {
      padding-right: var(--emu-common-spacing-xs);
    }
  }

  .header {
    &__global-navigation {
      display: none;
    }

    &__content {
      @include mq('large') {
        display: flex;
        align-items: center;
        width: 100%;
      }

      > .container {
        width: 100%;
      }

      > div:first-child {
        display: none;
        @include mq('large') {
          display: block;
          width: 97.5%; //to match figma
          position: absolute;
          top: -28px;
          right: 20px;
        }

        .header__menu-eyebrow-navlink {
          &--view-patient {
            display: none;
          }

          ul {
            display: flex;
            justify-content: flex-end;
            a {
              color: var(--emu-common-colors-white);
              transition: all 0.3s;
              font-weight: var(--emu-semantic-font-weight-500);
              font-size: var(--emu-common-font-sizes-narrow-medium);
              line-height: 22px;

              @include mq('large') {
                font-size: 15px;
                font-weight: var(--emu-common-font-weight-regular);
              }

              &:hover {
                color: var(--emu-semantic-colors-primary-pink-600);
              }
            }
          }
        }
      }
    }

    &__menu {
      position: fixed;
      top: 94px;
      right: -100%;
      height: calc(100vh - 94px);
      width: var(--emu-common-sizing-none);
      left: auto;
      transition: all 0.3s ease-in-out;
      overflow-y: auto;

      @include mq('large') {
        position: static;
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        align-items: center;
      }

      > div:first-child {
        @include mq('large') {
          margin-left: auto;
          margin-right: auto;
        }
      }

      &-fap {
        position: fixed;
        top: 54px;
        left: 0;
        background-color: var(--emu-semantic-colors-secondary-light-gray-100);
        width: 100%;
        display: flex;
        justify-content: flex-end;
        text-transform: uppercase;
        z-index: var(--emu-common-other-z-index-header);

        a {
          font-size: var(--emu-common-font-sizes-narrow-medium);
          line-height: var(--emu-semantic-line-heights-narrow-large);
          font-weight: var(--emu-common-font-weight-bold);
          padding-top: 10px;
          padding-bottom: 10px;
          padding-left: var(--emu-common-spacing-small);
          padding-right: var(--emu-common-spacing-small);
          display: flex;
          border: none;
          align-items: center;
          white-space: pre;
          text-transform: uppercase;

          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-wide-medium);
            line-height: var(--emu-semantic-line-heights-wide-large);
          }
        }

        li {
          border: none;
        }

        img {
          margin-right: var(--emu-common-spacing-small);
          margin-top: var(--emu-common-spacing-none);
          width: 9px;
          height: auto;

          @include mq('large') {
            display: none;
          }
        }

        @include mq('large') {
          display: block;
          padding: var(--emu-common-spacing-none);
          background-color: var(--emu-common-colors-transparent);
          position: static;
          text-transform: unset;
        }

        a {
          @include mq('large') {
            font-weight: var(--emu-common-font-weight-bold);
          }
        }
      }

      &.js-toggle-on {
        right: 0;
        width: 100%;
        background-color: var(--emu-common-colors-white);
      }

      &-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include mq('large') {
          padding-left: 20px;
          padding-right: 20px;
        }
      }

      &-trigger {
        &.aaaem-button {
          position: relative;
          padding-top: 10px;
          padding-bottom: 9px;
          margin-bottom: 6px;

          @include mq('large') {
            display: none;
          }

          &:hover,
          &:active,
          &:focus {
            padding-top: 10px;
            padding-bottom: 9px;
          }

          > span.chevron,
          > span,
          &::before,
          &::after {
            width: 23px; // as per live
            height: 2px; // as per live
            background-color: var(--emu-semantic-colors-primary-purple-1200);
            transition: all 0.25s ease-in-out;
            border-radius: var(--emu-common-border-radius-xxs);
          }

          > span.chevron {
            position: absolute;
            display: block;
            transform: rotate(0deg);

            .chevron-down {
              display: none;
            }
          }

          &::before,
          &::after {
            content: '';
            position: absolute;
            left: 0;
          }

          &::before {
            top: 1.5px; // to match live
          }

          &::after {
            bottom: 0.5px; // to match live
          }

          &.js-toggle-on {
            &::after {
              width: var(--emu-common-sizing-none);
              left: 50%;
            }
            &::before {
              width: var(--emu-common-sizing-none);
              top: 18px;
              left: 50%;
            }

            > span.chevron {
              transform: rotate(-45deg);
            }
            > span {
              transform: rotate(45deg);
            }
          }
        }
      }
    }

    &__main-logo {
      width: 120px; // as per figma
      height: 34px; // as per figma
    }
  }
}

.overflow-hidden {
  overflow: hidden;
  @include mq('large') {
    overflow: auto;
  }
}

.menu-open {
  overflow: hidden;

  @include mq('large') {
    overflow-y: auto;
  }
}

.has-sticky-header {
  header.header {
    top: -300px; // value that makes the header hidden to the top

    @include mq('large') {
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  &.menu-open,
  &.show-sticky-header {
    header.header {
      top: 0;
    }
  }

  &.show-sticky-header {
    header.header .header__menu-fap {
      box-shadow: 0 2px 4px var(--emu-semantic-colors-secondary-dark-gray-400);
      @include mq('large') {
        box-shadow: none;
      }
    }
  }

  &.show-sticky-header.menu-open {
    header.header .header__menu-fap {
      box-shadow: 0 2px 4px var(--emu-common-colors-transparent);
    }
  }
}

.skip-menu-container {
  @include mq('large') {
    display: none;
  }
}

// specific styles for HCP home page
#hcp-home {
  .header {
    &__content {
      .header__menu-eyebrow-navlink {
        &--view-patient {
          display: block;
        }
        &--healthcare-professional {
          display: none;
        }
      }
    }
  }
}

.cmp-experiencefragment--header {
  margin-top: 94px;
  @include mq('large') {
    margin-top: var(--emu-common-spacing-none);
  }
}
