.aaaem-carousel {
  @include mq('x-large') {
    margin-left: auto;
    margin-right: auto;
  }

  .tns-slider {
    transition-timing-function: linear;
  }
}
