#hcp-home {
  .hero-section {
    position: relative;

    &__teaser {
      .teaser-container {
        .aaaem-image {
          @include mq('large') {
            padding-top: 128px;
          }

          img {
            width: 100%;
            max-width: 508px; // to match figma
            margin-left: auto;
          }
        }

        .content-container {
          @include mq('xx-large') {
            padding-left: var(--emu-common-spacing-none);
            width: 100%;
          }
        }

        .content {
          width: 60%; // to match figma
          margin-left: var(--emu-common-spacing-none);

          @include mq('large') {
            padding-top: 114px;
            width: 100%;
            margin-left: auto;
          }

          @include mq('xx-large') {
            padding-left: var(--emu-common-spacing-medium);
            padding-right: var(--emu-common-spacing-medium);
            max-width: var(
              --emu-semantic-sizing-breakpoints-xx-large
            ); // needed to match the width of the header
          }

          @include mq('large') {
            //to match figma
            max-width: 80%;
            margin-left: var(--emu-common-spacing-none);
          }

          @include mq('1560px') {
            //needed to match the alignement of title text in 2 rows
            max-width: 1300px;
            margin-left: auto;
          }
        }

        .cmp-teaser__description {
          display: none;

          @include mq('large') {
            max-width: 1200px;  //to match figma
            display: block;
          }
        }

        .title-lockup {
          h3 {
            font-size: var(--emu-semantic-font-sizes-narrow-xl);
            line-height: var(--emu-semantic-line-heights-narrow-xl);

            @include mq('large') {
              width: 100%;
              font-size: var(--emu-semantic-font-sizes-wide-xxxl);
              line-height: var(--emu-semantic-line-heights-wide-xxxl);
              padding-bottom: 44px; //to match figma
            }

            br {
              display: none;

              @include mq('large') {
                display: block;
              }
            }
          }
        }
      }
    }

    &__description {
      padding-top: 20px;
      padding-left: var(--emu-common-spacing-small);
      padding-right: var(--emu-common-spacing-small);
    }
  }

  .form-container {
    @include mq('large') {
      max-width: 1168px; // to match the design
    }

    .form-content {
      @include mq('large') {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      > .options,
      > .text,
      > .recaptcha {
        @include mq('large') {
          width: calc(50% - 20px);
        }
      }

      .cmp-ajax-form-error,
      > .container {
        width: 100%;
      }
    }

    &__btn-wrapper {
      flex-direction: column-reverse;

      @include mq('large') {
        margin-top: 24px;
        flex-direction: column;
      }
    }

    &__bottom-text {
      @include mq('large') {
        margin-left: -16px;
        margin-right: -16px;
      }
    }
  }
}
