.emu-google-maps {
  margin-left: var(--emu-common-spacing-small);
  margin-right: var(--emu-common-spacing-small);
  padding-bottom: 62.5%; // to match figma

  @include mq('large') {
    margin-left: var(--emu-common-spacing-medium);
    margin-right: var(--emu-common-spacing-medium);
    padding-bottom: var(--emu-common-spacing-none);
    height: 918px; // as per figma
  }

  @include mq('xx-large') {
    max-width: calc(
      var(--emu-semantic-sizing-breakpoints-xx-large) -
        var(--emu-common-spacing-large)
    );
    margin-left: auto;
    margin-right: auto;
  }
}

// marker container
.gm-style-iw {
  p {
    //to make popup pointers on map readable
    margin-bottom: var(--emu-common-spacing-none);
    font-size: 10px;
    line-height: 15px;
    @include mq('large') {
      margin-bottom: var(--emu-common-spacing-medium);
      font-size: var(--emu-common-font-sizes-narrow-large);
      line-height: var(--emu-common-line-heights-narrow-large);
    }
  }

  a,
  b {
    color: var(--emu-semantic-colors-primary-purple-1100);
  }
}
