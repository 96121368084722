.emu-comparison-slider {
  display: flex;
  justify-content: center;
  max-width: 600px; //to match figma
  margin-left: auto;
  margin-right: auto;

  @include mq('large') {
    justify-content: unset;
  }

  img-comparison-slider.rendered {
    width: 100%;
  }

  &__handle {
    margin-left: -1px; // to overwrite default value
    width: 40px; //to match figma
    border-radius: var(--emu-common-sizing-medium);

    @include mq('large') {
      width: var(--emu-common-sizing-large); //to match figma
      padding-top: 24px;
      padding-bottom: 24px;
      padding-left: var(--emu-common-spacing-small);
      padding-right: var(--emu-common-spacing-small);
    }
  }

  img {
    width: 100%;
    height: auto;
  }

  &__arrow {
    transform: none;
    width: var(--emu-common-sizing-none);
    height: var(--emu-common-sizing-none);
    border-top: 5px solid var(--emu-common-colors-transparent);
    border-bottom: 5px solid var(--emu-common-colors-transparent);
    margin-top: var(--emu-common-spacing-xs);
    margin-top: var(--emu-common-spacing-none);

    @include mq('large') {
      border-top-width: 8px;
      border-bottom-width: 8px;
    }

    &--left {
      border-right: 8px solid var(--emu-common-colors-white);
      border-left: none;

      @include mq('large') {
        border-right-width: 14px;
      }
    }

    &--right {
      border-left: 8px solid var(--emu-common-colors-white);
      border-right: none;

      @include mq('large') {
        border-left-width: 14px;
      }
    }
  }
}
